import React, { createContext, useContext, useState, useEffect } from 'react';

//permet de partager l'état des données de facturation entre différents composants
//1. créer le contexte
const FacturationContext = createContext();

const getLocalFacturationData = () => {
  let localFacturationData = localStorage.getItem('facturation_data');
  if(localFacturationData == null){
    return null
  } else{
    return JSON.parse(localFacturationData);
  }
};

//2. Fournir le contexte
export const FacturationProvider = ({ children }) => {
  const [facturation_data, setFacturation_data] = useState(getLocalFacturationData);


  // Sauvegarder l'état du panier dans localStorage chaque fois que celui-ci est mis à jour
  useEffect(() => {
    localStorage.setItem('facturation_data', JSON.stringify(facturation_data));
  }, [facturation_data]);


  useEffect(() => {
    const savedFacturation_data= localStorage.getItem('facturation_data');
    if (savedFacturation_data) {
  
        setFacturation_data(JSON.parse(savedFacturation_data));
    } else {
      setFacturation_data([]);
    }
  }, []);


  return (
    <FacturationContext.Provider value={{facturation_data, setFacturation_data }}>
      {children}
    </FacturationContext.Provider>
  );
};

//Hook pour utiliser le contexte dans les autres classes
export const useFacturation = () => useContext(FacturationContext);
