import { Routes, Route,  useLocation } from 'react-router-dom';
import Home from './wine/screen/Home'
import QrCode from './wine/screen/QrCode';
import Wine from './wine/screen/Wine';
import Shop from './wine/screen/Shop';
import { FooterMusic } from './music/components/ui/Footer';
import OrderDetails from './wine/screen/OrderDetails';
import FacturationDetails from './wine/screen/FacturationDetails';
import { Navigation} from './wine/components/ui/Header';
import { NavigationMusic } from './music/components/ui/Header';
import Footer from './wine/components/ui/Footer';
import { CartProvider } from './wine/context/CartContext';
import { FacturationProvider } from './wine/context/FacturationContext';
import './wine/styles/Base.css';
import OrderValid from './wine/screen/OrderValid';
import Login from './wine/screen/Login';
import Administration from './wine/screen/Administration';
import Vineyard from './wine/screen/Vineyard';
import Music from './music/screen/Music'
import { useEffect } from 'react';
import { Construction } from './music/components/Construction';
import { HomePage } from './erwan_tapparel/Homepage';
import { Bisses } from './music/screen/Projets/Bisses';
import { Condition_vente } from './wine/condition_vente';

const ScrollToHashWithOffset = ({ offset }) => {
    const { hash } = useLocation();
  
    useEffect(() => {
      if (hash) {
        // Ajoutez un délai pour s'assurer que le DOM est rendu avant de défiler
        setTimeout(() => {
          const element = document.getElementById(hash.replace("#", ""));
          if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;
  
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
            });
          }
        }, 100);
      }
    }, [hash, offset]);
  
    return null;
  };

  function App() {
    const location = useLocation();
    const isWineRoute = location.pathname.startsWith('/code-qr') || location.pathname.startsWith('/cave') ||  location.pathname.startsWith('/connexion') || location.pathname.startsWith('/administration');
    const isMusicRoute = location.pathname.startsWith('/musique');
    const isErwanTapparel = location.pathname.startsWith('/');

    return (
        <>
            {isWineRoute && (
                <CartProvider>
                    <FacturationProvider />
                    <div className="body">
                        {location.pathname !== "/code-qr/" && <Navigation />}
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/cave" element={<Home />} />
                            <Route path="/code-qr" element={<QrCode />} />
                            <Route path="/cave/vins" element={<Wine />} />
                            <Route path="/cave/panier" element={<Shop />} />
                            <Route path="/cave/panier/details-facturation" element={<FacturationDetails />} />
                            <Route path="/cave/panier/details-commande" element={<OrderDetails />} />
                            <Route path="/cave/panier/valider-commande" element={<OrderValid />} />
                            <Route path="/connexion" element={<Login />} />
                            <Route path="/administration" element={<Administration />} />
                            <Route path="/cave/vignoble" element={<Vineyard />} />
                            <Route path="/cave/condition/" element={<Condition_vente/>} />
                        </Routes>
                        <Footer />
                    </div>
                    <FacturationProvider />
                </CartProvider>
            )}

            {isMusicRoute && (
             <>
                    <ScrollToHashWithOffset offset={70} /> {/* Passez votre offset ici */}
                    <NavigationMusic />
                    <Routes>      
                        <Route exact path="/musique" element={<Music />} />
                        <Route exact path="/musique/compositions" element={<Construction />} />
                        <Route exact path="/musique/instruments" element={<Construction />} />
                        <Route exact path="/musique/projets" element={<Bisses />} />
                        <Route exact path="/musique/constructions" element={<Construction />} />
                    </Routes>
                    <FooterMusic/>
                    </>
            )}

            {isErwanTapparel && (
              <>
                  <Routes>      
                        <Route exact path="/" element={<HomePage />} />
                        </Routes>
                        </>
            )}
        </>
    );
}


export default App;