import '../erwan_tapparel/homepage.css'
import { useNavigate } from 'react-router-dom';
import music from './musique.jpg'
import vin from './vins.jpg'

export const HomePage = () => {
    const navigate = useNavigate();

    return (
        <div className="homepage">
            <div className="intro">
                <h2> Bienvenue ! </h2>
                <p>Erwan, expert en musique et en vin, est ici pour partager sa passion avec vous. Que vous soyez fasciné par les harmonies musicales ou par les saveurs des grands crus, il propose des expériences exceptionnelles dans les deux domaines. Faites votre choix et plongez dans l'univers qui vous attire le plus !        </p>         </div>
                <div className="choices">
                <div className="choice music" onClick={() => navigate('/musique')}>
                    <div className="overlay_erwan">
                        <h1>Musique</h1>
                    </div>
                </div>
                <div className="choice wine" onClick={() => navigate('/cave')}>
                    <div className="overlay_erwan">
                        <h1>Vins</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};