import WineDisplay from "../components/wines/WineDisplay";
import { Title } from "../components/ui/Utilities";
/**
 * Screen for the Wine page. 
 */

export default function Wine() {
    return (
        <>
        <Title title = "Les vins"/>
        <WineDisplay/>
        </>
    )
}