import { useState, useRef } from 'react'
import '../../styles/audioplayer.css'

export const LocalPlayer = ({ src }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(new Audio(src));

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div>
            <button onClick={togglePlayPause}>
                {isPlaying ? 'Pause' : 'Play'}
            </button>
        </div>
    );
};

export const YoutubePlayer = ({ src}) => {
    return (
        <div className='video-container'>
          <iframe
          
            src={src}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player"
            className='youtube_band'
          ></iframe>
        </div>
      );
}

export const SoundCloudPlayer = ({ trackUrl, soundCloud}) => {
    return (
        <div>
            <iframe className={soundCloud}
                title='music'
          
                height="166"
                src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(trackUrl)}&color=%23C2A770&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=true&visual=false`}
            ></iframe>
        </div>
    );
}
SoundCloudPlayer.defaultProps = {
    autoPlay: false,
    hideRelated: false,
    showComments: true,
    showUser: true,
    showReposts: false,
    showTeaser: true,
    visual: true,
    color: "%23ff5500" // Default color is orange
  };