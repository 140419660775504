import { useEffect, useState } from "react";
import { db, storage } from "../../../config/InitFirebase";
import { addDoc, collection, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import '../../styles/FormManage.css';

const winesCollectionRef = collection(db, "vins");

export const DeleteWineForm = ({ wine }) => {

  const deleteWine = async (e) => {
    e.preventDefault();
    const wineDoc = doc(db, "vins", wine.id);
    await deleteDoc(wineDoc);
    alert("Le vin à été supprimé")
  };

  return(
    <div className="btn_container">
    <button type="submit" onClick={deleteWine} className="btn_manage">Supprimer la sélection</button>
    </div>
  )
};


export const UdpateWineForm = ({ wine }, { onFormClose }) => {

  const [updatedVariety, setUpdatedVariety] = useState(wine.variety);
  const [updatedMillesime, setUpdatedMillesime] = useState(wine.millesime);
  const [updatedLocation, setUpdatedLocation] = useState(wine.location);
  const [updatedJoke, setUpdatedJoke] = useState(wine.joke);
  const [updatedFileUpload, setUpdatedFileUpload] = useState(null);
  const [updatedAlcoholLevel, setUpdatedAlcoholLevel] = useState(wine.alcohol_level);
  const [updatedBottleSize, setUpdatedBottleSize] = useState(wine.bottle_size);
  const [updatedDescription1, setUpdatedDescription1] = useState(wine.description_1);
  const [updatedDescription2, setUpdatedDescription2] = useState(wine.description_2);
  const [updatedDescription3, setUpdatedDescription3] = useState(wine.description_3);
  const [updatedDescription4, setUpdatedDescription4] = useState(wine.description_4);
  const [updatedDescriptionDetails1, setUpdatedDescriptionDetails1] = useState(wine.description_details_1);
  const [updatedDescriptionDetails2, setUpdatedDescriptionDetails2] = useState(wine.description_details_2);
  const [updatedDescriptionDetails3, setUpdatedDescriptionDetails3] = useState(wine.description_details_3);
  const [updatedDescriptionDetails4, setUpdatedDescriptionDetails4] = useState(wine.description_details_4);
  const [updatedPrice, setUpdatedPrice] = useState(wine.price);

  useEffect(() => {
    setUpdatedVariety(wine.variety);
    setUpdatedMillesime(wine.millesime);
    setUpdatedFileUpload(wine.image);
    setUpdatedLocation(wine.location);
    setUpdatedJoke(wine.joke);
    setUpdatedAlcoholLevel(wine.alcohol_level);
    setUpdatedBottleSize(wine.bottle_size);
    setUpdatedDescription1(wine.description_1);
    setUpdatedDescription2(wine.description_2);
    setUpdatedDescription3(wine.description_3);
    setUpdatedDescription4(wine.description_4);
    setUpdatedDescriptionDetails1(wine.description_details_1);
    setUpdatedDescriptionDetails2(wine.description_details_2);
    setUpdatedDescriptionDetails3(wine.description_details_3);
    setUpdatedDescriptionDetails4(wine.description_details_4);
    setUpdatedPrice(wine.price);

  }, [wine]);

  const updateWine = async (e) => {
    e.preventDefault();
    const wineDoc = doc(db, "vins", wine.id);
    let imageURL = wine.image;
    console.log(imageURL)

    if (updatedFileUpload && updatedFileUpload !== wine.image) {
      const filesFolderRef = ref(storage, `wines/${updatedFileUpload.name}`);
      try {
        await uploadBytes(filesFolderRef, updatedFileUpload);
        imageURL = await getDownloadURL(filesFolderRef);
      } catch (err) {
        console.error(err);
      }
    }

    try {
      await updateDoc(wineDoc, {
        variety: updatedVariety,
        millesime: updatedMillesime,
        image: imageURL,
        location: updatedLocation,
        joke: updatedJoke,
        alcohol_level: updatedAlcoholLevel,
        bottle_size: updatedBottleSize,
        description_1: updatedDescription1,
        description_2: updatedDescription2,
        description_3: updatedDescription3,
        description_4: updatedDescription4,
        description_details_1:updatedDescriptionDetails1,
        description_details_2:updatedDescriptionDetails2,
        description_details_3:updatedDescriptionDetails3,
        description_details_4:updatedDescriptionDetails4,
        price: updatedPrice,
      });
      alert("Le vin à été modifié")
      onFormClose();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={(updateWine)}>
      <div className="lbl_space">
        <label className="label_manage">Cépage : </label>
        <input className="input_space"
          type="text"
          value={updatedVariety}
          onChange={(e) => setUpdatedVariety(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <label  className="label_manage">Millesime : </label>
        <input className="input_space"
          type="number"
          value={updatedMillesime}
          onChange={(e) => setUpdatedMillesime(Number(e.target.value))}
        />
      </div>
      <div className="lbl_space">
        <label  className="label_manage">Lieu : </label>
        <input className="input_space"
          type="text"
          value={updatedLocation}
          onChange={(e) => setUpdatedLocation(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <label  className="label_manage">Pharse type (blague) : </label>
        <input className="input_space"
          type="text"
          value={updatedJoke}
          onChange={(e) => setUpdatedJoke(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <label  className="label_manage">Image : </label>
        <input className="input_space"
          type="file"
          onChange={(e) => setUpdatedFileUpload(e.target.files[0])}
        />
      </div>
      <div className="lbl_space">
        <label  className="label_manage">Taux d'alcool : </label>
        <input className="input_space"
          type="number"
          value={updatedAlcoholLevel}
          onChange={(e) => setUpdatedAlcoholLevel(Number(e.target.value))}
        />
      </div>
      <div className="lbl_space">
        <label  className="label_manage">Taille de la bouteille : </label>
        <input className="input_space"
          type="number"
          value={updatedBottleSize}
          onChange={(e) => setUpdatedBottleSize(Number(e.target.value))}
        />
      </div>
      <div className="lbl_space">
      <textarea className="textarea_manage">Description 1 : </textarea>
        <textarea className="textarea_space"
          type="text"
          value={updatedDescription1}
          onChange={(e) => setUpdatedDescription1(e.target.value)}
        />
      </div>
      <div className="lbl_space">
      <textarea className="textarea_manage">Description 2 : </textarea>
      <textarea className="textarea_space"
          type="text"
          value={updatedDescription2}
          onChange={(e) => setUpdatedDescription2(e.target.value)}
        />
      </div>
      <div className="lbl_space">
      <textarea className="textarea_manage">Description 3 : </textarea>
       <textarea className="textarea_space"
          type="text"
          value={updatedDescription3}
          onChange={(e) => setUpdatedDescription3(e.target.value)}
        />
      </div>
      <div className="lbl_space">
      <textarea className="textarea_manage">Description 4 : </textarea>
         <textarea className="textarea_space"
          type="text"
          value={updatedDescription4}
          onChange={(e) => setUpdatedDescription4(e.target.value)}
        />
      </div>
      <div className="lbl_space">
      <textarea className="textarea_manage">Description détaillée (popup) paragraphe 1 : </textarea>
         <textarea className="textarea_space"
          type="text"
          value={updatedDescriptionDetails1}
          onChange={(e) => setUpdatedDescriptionDetails1(e.target.value)}
        />
      </div>
      <div className="lbl_space">
      <textarea className="textarea_manage">Description détaillée (popup) paragraphe 2 : </textarea>
         <textarea className="textarea_space"
          type="text"
          value={updatedDescriptionDetails2}
          onChange={(e) => setUpdatedDescriptionDetails2(e.target.value)}
        />
      </div>
      <div className="lbl_space">
      <textarea className="textarea_manage">Description détaillée (popup) paragraphe 3 : </textarea>
         <textarea className="textarea_space"
          type="text"
          value={updatedDescriptionDetails3}
          onChange={(e) => setUpdatedDescriptionDetails3(e.target.value)}
        />
      </div>
      <div className="lbl_space">
      <textarea className="textarea_manage">Description détaillée (popup) paragraphe 4 : </textarea>
         <textarea className="textarea_space"
          type="text"
          value={updatedDescriptionDetails4}
          onChange={(e) => setUpdatedDescriptionDetails4(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <label className="label_manage">Prix : </label>
        <input className="input_space"
          type="number"
          value={updatedPrice}
          onChange={(e) => setUpdatedPrice(Number(e.target.value))}
        />
      </div>
      <div className="btn_container"> 
      <button type="submit" className="btn_manage">Mettre à jour la sélection</button>
      </div>
    </form>
  )
};

export const AddWineForm = ({onFormClose}) => {
  const [newVariety, setNewVariety] = useState("");
  const [newMillesime, setNewMillesime] = useState(0);
  const [newLocation, setNewLocation] = useState("");
  const [newJoke, setnewJoke] = useState("");
  const [newFileUpload, setNewFileUpload] = useState(null);
  const [newAlcoholLevel, setNewAlcoholLevel] = useState(0);
  const [newBottleSize, setNewBottleSize] = useState(0);
  const [newDescription1, setNewDescription1] = useState("");
  const [newDescription2, setNewDescription2] = useState("");
  const [newDescription3, setNewDescription3] = useState("");
  const [newDescription4, setNewDescription4] = useState("");
  const [newDescriptionDetailed1, setNewDescriptionDetailed1] = useState("");
  const [newDescriptionDetailed2, setNewDescriptionDetailed2] = useState("");
  const [newDescriptionDetailed3, setNewDescriptionDetailed3] = useState("");
  const [newDescriptionDetailed4, setNewDescriptionDetailed4] = useState("");
  const [newQuantity, setNewQuantity] = useState(0);
  const [newPrice, setNewPrice] = useState(0);

  const onSubmitWine = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    let imageURL = ' ';

    if (newFileUpload) {
      const filesFolderRef = ref(storage, `wines/${newFileUpload.name + v4()}`);
      try {
        await uploadBytes(filesFolderRef, newFileUpload);
        imageURL = await getDownloadURL(filesFolderRef);
      } catch (err) {
        console.error(err);
      }
    }

    try {
      await addDoc(winesCollectionRef, {
        variety: newVariety,
        millesime: newMillesime,
        location: newLocation,
        joke:newJoke,
        image: imageURL,
        alcohol_level: newAlcoholLevel,
        bottle_size: newBottleSize,
        description_1: newDescription1,
        description_2: newDescription2,
        description_3: newDescription3,
        description_4: newDescription4,
        description_details_1:newDescriptionDetailed1,
        description_details_2:newDescriptionDetailed2,
        description_details_3:newDescriptionDetailed3,
        description_details_4:newDescriptionDetailed4,
        initial_quantity: newQuantity,
        price: newPrice,
      });

      alert("Le vin à été ajouté")
      onFormClose();
      
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <form onSubmit={onSubmitWine}>
      <div className="lbl_space">
        <label className="label_manage">Cépage : </label>
        <input className="input_space"
          type="text"
          value={newVariety}
          onChange={(e) => setNewVariety(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <label className="label_manage">Année : </label>
        <input className="input_space"
          type="number"
          value={newMillesime}
          onChange={(e) => setNewMillesime(Number(e.target.value))}
        />
      </div>
      <div className="lbl_space">
        <label className="label_manage">Lieu : </label>
        <input className="input_space"
          type="text"
          value={newLocation}
          onChange={(e) => setNewLocation(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <label className="label_manage">Texte (blague) : </label>
        <input className="input_space"
          type="text"
          value={newJoke}
          onChange={(e) => setnewJoke(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <label className="label_manage">Image : </label>
        <input className="input_space"
          type="file"
          onChange={(e) => setNewFileUpload(e.target.files[0])}
        />
      </div>
      <div className="lbl_space">
        <label className="label_manage">Niveau d'alcool : </label>
        <input className="input_space"
          type="number"
          value={newAlcoholLevel}
          onChange={(e) => setNewAlcoholLevel(Number(e.target.value))}
        />
      </div>
      <div className="lbl_space">
        <label className="label_manage">Taille de la bouteille : </label>
        <input className="input_space"
          type="number"
          value={newBottleSize}
          onChange={(e) => setNewBottleSize(Number(e.target.value))}
        />
      </div>
      <div className="lbl_space">
        <textarea className="textarea_manage">Description 1 : </textarea>
        <textarea className="textarea_space"
          type="text"
          value={newDescription1}
          onChange={(e) => setNewDescription1(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <textarea className="textarea_manage">Description 2 : </textarea>
        <textarea className="textarea_space"
          type="text"
          value={newDescription2}
          onChange={(e) => setNewDescription2(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <textarea className="textarea_manage">Description 3 : </textarea>
        <textarea className="textarea_space"
          type="text"
          value={newDescription3}
          onChange={(e) => setNewDescription3(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <textarea className="textarea_manage">Description 4 : </textarea>
        <textarea className="textarea_space"
          type="text"
          value={newDescription4}
          onChange={(e) => setNewDescription4(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <textarea className="textarea_manage">Description détaillée paragraphe 1 : </textarea>
        <textarea className="textarea_space"
          type="text"
          value={newDescriptionDetailed1}
          onChange={(e) => setNewDescriptionDetailed1(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <textarea className="textarea_manage">Description détaillée  paragraphe 2 : </textarea>
        <textarea className="textarea_space"
          type="text"
          value={newDescriptionDetailed2}
          onChange={(e) => setNewDescriptionDetailed2(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <textarea className="textarea_manage">Description détaillée  paragraphe 3 : </textarea>
        <textarea className="textarea_space"
          type="text"
          value={newDescriptionDetailed3}
          onChange={(e) => setNewDescriptionDetailed3(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <textarea className="textarea_manage">Description détaillée  paragraphe 4 : </textarea>
        <textarea className="textarea_space"
          type="text"
          value={newDescriptionDetailed4}
          onChange={(e) => setNewDescriptionDetailed4(e.target.value)}
        />
      </div>
      <div className="lbl_space">
        <label className="label_manage">Quantité initial : </label>
        <input className="input_space"
          type="number"
          value={newQuantity}
          onChange={(e) => setNewQuantity(Number(e.target.value))}
        />
      </div>
      <div className="lbl_space">
        <label className="label_manage">Prix : </label>
        <input className="input_space"
          type="number"
          value={newPrice}
          onChange={(e) => setNewPrice(Number(e.target.value))}
        />
      </div>
      <div className="btn_container"> 
      <button type="submit" className="btn_manage">Ajouter le nouveau vin</button>
      </div>
    </form>
  );
};