import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../../styles/Home.css';
import { useEffect, useState } from "react";
import { listenToCarouselImages } from "../GetServices";
import { AnimatedDiv } from "../ui/Animations";

export function Presentation() {
    return (
        <div className="presentation_size_all">
            <div className="presentation_content1">
                <div className='presentation_text1'>
                    <AnimatedDiv delay={0.5}>
                    <p className="presentation_p1"> Situé au cœur du Valais, la cave du Sonneur vous ouvre ses portes dans un univers viticole, musical et parfois décalé.                  </p>       
                  <p className="Home_text_2"> "Au travers du brouillard s'étire un sentier <br/> Là où les empreintes suivent les glaciers </p>
                  <p className="Home_text_2"> Le bourdonnement alpin joue avec l'eau <br/> Puisque Artemis résonne au rythme des flots</p>
                  <p className="Home_text_2"> Les rayons réchauffent et dissipent le brouillard <br/> Pour que les pas recueillissent le nectar"</p>
                  <p className="Poeme_style"> - Erwan Tapparel - </p>
                  </AnimatedDiv>
                </div>
                </div>
            </div>

    )
}

export function Welcome() {
    const [carouselImages, setcarouselImages] = useState([]);

    useEffect(() => {
        const unsubscribe = listenToCarouselImages((data) => {
            setcarouselImages(data);
        });
        //unsubscribe permet d'éviter les fuites de mémoire
        return () => unsubscribe();
    }, []);

    return (
        <div className="carousel-container">
            <Carousel
                autoPlay
                interval={9000}
                infiniteLoop
                showIndicators={false}
                showStatus={false} >
                {carouselImages
                    .filter((e) => e.legend === "background")
                    .map((e) => (
                        <div className="slide" key={e.id}>

                            <img src={e.imageURL} alt="Domaine du sonneur" />
                            <div className="overlay">         
                                <h1 className="overlay_title"> Cave du Sonneur </h1>
                                <p className="overlay_text"> {e.text} </p>       
                            </div>
                        </div>
                    ))}
            </Carousel>
        </div>
    );
}