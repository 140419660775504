import '../../styles/ButtonFiltered.css';
import caveIcon from '../../assets/caveIcon.png'
import homeIcon from '../../assets/homeIcon.png'

const FilteredButtons = ({ items, selectedValue, setSelected }) => (
    <div className="btn_filter">
        {items.map((item, index) =>
            <button
                key={index}
                onClick={() => setSelected(item.value)}
                className={item.value === selectedValue ? "btn_millesime_selected" : "btn_millesime"}
            >
                {item.label}
                <div>
                    {item.value === "cave" && (
                        <div>
                            <img src={caveIcon} alt="Cave Icon" className='Image_size' />

                        </div>
                    )}
                    {item.value === "domicile" && (
                        <div>
                            <img src={homeIcon} alt="Domicile Icon" className='Image_size' />

                        </div>
                    )}
                </div>
            </button>
        )}

    </div>
);

export default FilteredButtons;