import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import ReactPlayer from 'react-player';
import bisseIcon from '../assets/position.png'
import { useState } from 'react';
import '../styles/map.css'
import { YoutubePlayer } from './ui/AudioPlayer';
// Icône personnalisée pour les bisses
const customIcon = new L.Icon({
    iconUrl: bisseIcon,
    iconSize: [20, 30],  // Taille de l'icône
  });

  export const Map = ({ bisseLocations }) => {
    const [videoUrl, setVideoUrl] = useState(null);

    const openVideo = (url) => {
        setVideoUrl(url);
      };
    
      const closeVideo = () => {
        setVideoUrl(null);
      };

      return (
        <div className='presentation_mobile'>
          <MapContainer
            center={[46.2, 7.6]} // Coordonnées centrales du Valais
            zoom={8} // Niveau de zoom initial
            className='map_container' // Taille de la carte
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // Source des tuiles de fond
            />
            {bisseLocations.map((bisse, index) => (
              <Marker
                key={index}
                position={[bisse.lat, bisse.lng]} // Position du bisse
                icon={customIcon} // Icône personnalisée pour chaque bisse
              >
                <Popup>
                  <div className="map_video">
                    <h4>{bisse.name}</h4>
                   <YoutubePlayer src={bisse.videoUrl}/>
                  </div>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
          {/* Modale vidéo */}
          {videoUrl && (
            <div className="modal">
              <div className="modal-content">
                <ReactPlayer url={videoUrl} playing controls />
                <button onClick={closeVideo}>Fermer</button>
              </div>
            </div>
          )}
        </div>
      );
    };