import { LoginDisplay } from "../components/login/LoginDisplay";


export default function Login() {
    return (
        <>

        <LoginDisplay/>
        </>
    )
}