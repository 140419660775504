import { MainTitle, Subtitle } from "./ui/Titles"
import profil from "../assets/profil.png"
import { YoutubePlayer, SoundCloudPlayer } from "./ui/AudioPlayer"
import '../styles/music_container.css'
import musical_1 from '../assets/musical.jpg'
import { Link, useNavigate } from 'react-router-dom';
import { Element } from 'react-scroll';
import { AnimatedDiv, ImageSlider } from '../components/ui/Animations';

export const Presentation = () => {
    return (

        <div>
            <div className="presentation_container_1">
                <div className="presentation_content">
                    <div className="title_color">
                    <MainTitle title="Erwan Tapparel" />
                    </div>
                    <Subtitle subtitle="Sonneur" />
                    <AnimatedDiv delay={0.5}>
                        <div className="btn_presentation_contact">
                            <p className="contact_text">
                                <i className="fas fa-phone-alt" style={{ marginRight: '8px' }}></i>
                                078 / 679 67 54
                            </p>
                            <p className="contact_text">
                                <i className="fas fa-envelope" style={{ marginRight: '8px' }}></i>
                                musique@erwantapparel.ch
                            </p>
                        </div>
                    </AnimatedDiv>
                </div>
                <AnimatedDiv delay={0.5}>
                    <img src={profil} alt="profil" className="img_profil_home" />
                </AnimatedDiv>
            </div>
            <AnimatedDiv delay={0.5}>
                <SoundCloudPlayer trackUrl="https://soundcloud.com/tapparelerwan/abbeys-reel?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" soundCloud="soundcloud_presentation" />
            </AnimatedDiv>
            <div className="presentation_container_2">
            </div>
        </div>
    )
}

export const About = () => {
    const paragraph_1 = "Je m'appelle Erwan Tapparel, et depuis plus de 20 ans, la cornemuse écossaise fait partie intégrante de ma vie. Mon parcours a débuté avec l'enseignement de Fred Morrison par de nombreux stages au Centre Amzer Nevez en Bretagne et a continué à l’aide de Xavier Boderiou par Skype durant 8 ans, un travail qui s'est poursuivi avec l'obtention d'un diplôme au prestigieux au National Piping Centre de Glasgow en 2017. En tant que compétiteur, j'ai remporté plusieurs prix en Suisse et participé à des concours renommés en Écosse et à Londres.";
    const paragraph_1_part2 = "Actuellement, je joue dans le bagad Pommerit - le - Vicomte,  de 1ère catégorie en Bretagne, une expérience enrichissante qui me permet de fusionner les traditions musicales écossaises et bretonnes.";
    const paragraph_1_part3 = "En tant que Pipe Major du Traditional Pipe Band of Lausanne, je dirige un groupe qui partage ma passion pour la musique écossaise, et ensemble, nous apportons ce son puissant et envoûtant au public suisse.";
    const paragraph_2 = "Je suis également un joueur de Uilleann pipe, instrument que j’ai appris auprès de maîtres comme Sheila Friel, Tom Delany et Cillian Vallely. Je participe activement à la scène de la musique traditionnelle irlandaise en Suisse, en organisant des sessions et en jouant dans divers événements.";
    const paragraph_2_part2 = "Pour ceux qui souhaitent s'initier à la cornemuse / Uilleann pipe ou perfectionner leur jeu, je propose des cours en présentiel ou via Skype. Mon enseignement est personnalisé pour répondre aux besoins de chaque élève, afin de leur permettre d'atteindre leurs objectifs musicaux."

    return (
        <div>
            <div className="about_container">
                <div className="about_content">
                    <AnimatedDiv delay={0.5}>
                    <div className="title_color">
                        <MainTitle title="Biographie" />
                        </div>
                        <p className="about_text"> {paragraph_1} <br /><br />{paragraph_1_part2} <br /><br /> {paragraph_1_part3} </p>
                        <YoutubePlayer src="https://www.youtube.com/embed/akZdowNmVSs" />
                        <p className="about_text"> <br /> {paragraph_2}  <br /><br /> {paragraph_2_part2} </p>
                    </AnimatedDiv>
                </div>
            </div>  
        </div>
    )
}

export const Prestations = () => {

    const event1 = "Evènements personnels"
    const event1_description = "Pour vos mariages, anniversaires ou soirées privées, la cornemuse apporte une touche distinctive qui marque chaque moment de manière unique."

    const event2 = "Cérémonies et mémoires"
    const event2_description = "La cornemuse apporte une note solennelle et respectueuse lors d’enterrements, d’inaugurations ou tout autre moment de recueillement."

    const event3 = "Evènements sportifs"
    const event3_description = "Apportez un souffle nouveau à vos événements sportifs avec la cornemuse, un choix original pour marquer l’esprit des participants et du public."

    const event4 = "Prestations personnalisées"
    const event4_description = "Pour tout type d’événement, de la cérémonie privée à l’événement public, une prestation sur mesure qui s’adapte à l’ambiance souhaitée."

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/musique/constructions'); // Redirige vers la page "construction"
      };
    return (
        <div className="prestation_container_size">
            <AnimatedDiv delay={0.5}>
                <div className="prestation_title">
                    <MainTitle title="Prestations" />
                </div>
                <div className="prestations_contain">
                    <div className="prestations_content">
                        <span className="prestation_event_title"> {event1} </span>
                        <p className="prestion_event_description"> {event1_description} </p>
                    </div>
                    <div className="prestations_content">
                        <span className="prestation_event_title"> {event2} </span>
                        <p className="prestion_event_description"> {event2_description} </p>
                    </div>
                    <div className="prestations_content">
                        <span className="prestation_event_title"> {event3} </span>
                        <p className="prestion_event_description"> {event3_description} </p>
                    </div>
                    <div className="prestations_content">
                        <span className="prestation_event_title"> {event4} </span>
                        <p className="prestion_event_description"> {event4_description} </p>
                    </div>
                </div>
            </AnimatedDiv>
        </div>
    )
}

export const Enseignements = () => {

    const paragraph_1 = "Je propose des cours de cornemuse écossaise et Uilleann pipe, aussi bien en présentiel dans la région de Sierre/Sion qu’en ligne via Skype. Mes cours sont adaptés à tous les niveaux, que vous soyez débutant ou que vous souhaitiez perfectionner votre technique."
    const paragraph_2 = "Pour débuter la cornemuse écossaise, il est nécessaire de commencer sur un practice chanter, un instrument d'entraînement permettant d'apprendre les bases techniques avant de passer à la cornemuse elle-même. Les cours sont structurés autour d'exercices et de partitions, et il n'est pas indispensable de connaître le solfège, même si cela peut être un atout."
    const paragraph_3 = "Pour le Uilleann pipe, il est nécessaire de disposer d'un practice set, comprenant un chanter, une poche et un soufflet. L'apprentissage se fait principalement à l'oreille, en s'inspirant des enregistrements de grands musiciens. Là encore, aucune connaissance en solfège n’est requise pour débuter."

    return (
        <div>
              <AnimatedDiv delay={0.5}>
        <div className="enseignements_container">
                <div>
                <div className="title_color">
                    <MainTitle title="Enseignements" />
                    </div>
                    <p className="enseignements_text"> {paragraph_1} </p>
                    <p className="enseignements_text"> {paragraph_2} </p>
                    <p className="enseignements_text"> {paragraph_3} </p>
                </div>
        </div>
        <ImageSlider/>
        </AnimatedDiv>
        {/*
        <div className="about_container_2"/>
        */}
        </div>
    )
}

export const Compositions = () => {

    const paragraph_1 = "Parallèlement à mes projets d’exploration et de transmission, je compose régulièrement pour la cornemuse écossaise. J’écris dans des styles variés – hornpipe, marches, jigs, reels, etc – en cherchant à mettre en musique des moments de la vie courante ou des événements marquants.";
    const paragraph_2 = "Ce travail de création me permet d’enrichir mon répertoire et de donner une dimension plus personnelle à mon art."
    const paragraph_3 = "“ Composer est un processus spontané et souvent imprévisible, qui demande un esprit ouvert pour que les idées puissent prendre forme. “"

    const handleClick = () => {
       window.open("https://soundcloud.com/tapparelerwan", '_blank');

      };

    return (
        <div className="composition_container">
            <AnimatedDiv delay={0.5}>
            <div className="title_color">
                <MainTitle title="Compositions" />
                </div>
                <p  className="composition_text"> {paragraph_1} </p>
                <p  className="composition_text"> {paragraph_2} <br/>  {paragraph_3} </p>
                <div className="composition_soundclound_contain">
                <SoundCloudPlayer trackUrl="https://soundcloud.com/tapparelerwan/the-puffin?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" soundCloud="composition_soundCloud"/>
                <SoundCloudPlayer trackUrl="https://soundcloud.com/tapparelerwan/rocking-in-the-vineyard-blow-my-chanter-big-burning-jigs?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" soundCloud="composition_soundCloud"/> 
                </div>
                <button className="prestation_event_button" onClick={handleClick}> Découvrir d'autres morceaux </button>

         </AnimatedDiv>
        </div>
    )

}


export const Services = () => {
    return (
        <div>
            <div className="services_contain">
                <AnimatedDiv delay={0.5}>
                    <MainTitle title="Mes services" />
                    <div className="services_content">

                        <div className="service_item">
                            <div className="image-container">
                                <img src={musical_1} alt="Sessions traditionnelles" className="img_services" />
                                <div className="overlay_music">
                                    <Link to="/sessions" className="overlay_music_text">En savoir +</Link>
                                </div>
                            </div>
                            <div className="services_text"> <p> Sessions traditionnelles </p>
                                <div className="services_description"> <p> .......dafkdsfiwepd </p> </div>
                            </div>
                        </div>

                        <div className="service_item">
                            <div className="image-container">
                                <img src={musical_1} alt="Cours" className="img_services" />
                                <div className="overlay_music">
                                    <Link to="/sessions" className="overlay_music_text">En savoir +</Link>
                                </div>
                            </div>
                            <div className="services_text"> <p> Cours </p>
                                <div className="services_description"> <p> .......dafkdsfiwepd </p> </div>
                            </div>
                        </div>
                        <div className="service_item">
                            <div className="image-container">
                                <img src={musical_1} alt="Evènements" className="img_services" />
                                <div className="overlay_music">
                                    <Link to="/sessions" className="overlay_music_text">En savoir +</Link>
                                </div>
                            </div>
                            <div className="services_text"> <p> Evènements </p>
                                <div className="services_description"> <p> .......dafkdsfiwepd </p> </div>
                            </div>
                        </div>
                    </div>
                </AnimatedDiv>
            </div>
        </div>
    )
}



export const HomeSection = () => {
    return (
        <div>
           
            <Element name="biographie" className='section' id="biographie">
                <About />
            </Element>

            <Element name="prestations" className='section' id="prestations">
                <Prestations />
            </Element>

            <Element name="enseignements" className='section_enseignement' id="enseignements" >
                <Enseignements />
            </Element>
            <Element name="compositions" className='section' id="compositions" >
                <Compositions />
            </Element>

        </div>
    )
}