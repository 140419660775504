import React, { createContext, useContext, useState, useEffect } from 'react';

//permet de partager l'état du panier entre différents composants
//1. créer le contexte
const CartContext = createContext();

const getLocalCartData = () => {
  let localCartData = localStorage.getItem('cart');
  if(localCartData){
    return JSON.parse(localCartData);
  } else{
    return []
  }
};

//2. Fournir le contexte
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => getLocalCartData());

  // Sauvegarder l'état du panier dans localStorage chaque fois que celui-ci est mis à jour
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (wine, quantityToAdd) => {
    setCart((prevCart) => {
      prevCart = prevCart || [];
      const existingItemIndex = prevCart.findIndex(item => item.id === wine.id);
      if (existingItemIndex >= 0) {
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex].quantity += quantityToAdd;
        return updatedCart;
      } else {
        return [...prevCart, { ...wine, quantity: quantityToAdd }];
      }
    });
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, setCart }}>
      {children}
    </CartContext.Provider>
  );
};

//Hook pour utiliser le contexte dans les autres classes
export const useCart = () => useContext(CartContext);