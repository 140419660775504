import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../styles/Utilities.css';
import React from 'react';

//Title display at the top of each tab
export const Title = ({ title }) => {
  return (
    <div className="title_style"> 
    <p>   {title} </p>
   </div>
  )
}

//Title display to process of facturation (facturation details, order details)
export const TitleFacturationProcess = ({ title, subtitle }) => {
  return (
    <div className='facturationProcess_style'>
      <h2  className="title_facturationProcess"> {title} </h2>
      <p  className="subtitle_facturationProcess"> {subtitle} </p>
    </div>
  )
}


//Button to change tab
export const NextStep = ({ router, title, btn_next_location }) => {
  return (
    <div className={btn_next_location || "btn_content"} >
      <Button as={Link} to={router} className="btn_next" > {title} </Button>
    </div>
  )
}

//Button to valid
export const BtnSubmit = ({ title, btn_next_location  }) => {
  return (
    <div className={btn_next_location || "btn_contain_submit"}>
      <button type="submit" className="btn_next"> {title} </button>
    </div>
  )
}

export const Popup = ({ wine, show, onClose }) => {

  if (!show) {
    return null;
  }

  const getBackgroundClass = (description) => {
  
    description = description.toLowerCase();
    if (description.includes('barrique')) {
      return 'bg-barrel-popup';
    } else if (description.includes('raisin') || description.toLowerCase().includes('cep')) {
      return 'bg-grapes-popup';
    } else if (description.includes('elevage') || description.includes('cuve')) {
      return 'bg-bottle-popup';
    } else if (description.includes('parcelle')) {
      return 'bg-parcelle-popup';
    } else {
      return 'bg-wine-popup';
    }
  };

  return (
    <div className="popup_overlay" onClick={onClose}>
      <div className="popup" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={onClose}>&times;</span>
        <div className="popup_content1">
          <img src={wine.image} className="popup-bottle" alt={wine.variety}></img>
          <div className="popup_content2">
          <span className="popup-title"> {wine.variety} <br/> </span>
            <span className='popup-millesime'> Millésime {wine.millesime} </span>
           
            <ul className="popup_container">
            <li className={getBackgroundClass(wine.description_details_1)}> {wine.description_details_1} </li>
            <li className={getBackgroundClass(wine.description_details_2)}> {wine.description_details_2} </li>
            <li className={getBackgroundClass(wine.description_details_3)}> {wine.description_details_3} </li>
            <li className={getBackgroundClass(wine.description_details_4)}> {wine.description_details_4} </li>
            </ul>
            
             
            </div>

        </div>
      </div>


    </div>
  );
}