import './styles/Condition_vente.css'

export const Condition_vente = () => {
    
    return(
    <div className="condition_content">
     <title>Clauses Légales - Vente de Vins</title>


    <header className='condition_header'>
        <h3 >Clauses Légales</h3>
        <nav className='condition_champ'>
            <ul>
                <li><a href="#cgv" className='condition_a'>Conditions Générales de Vente (CGV)</a></li>
                <li><a href="#confidentialite" className='condition_a'>Politique de Confidentialité</a></li>
                <li><a href="#mentions-legales" className='condition_a'>Mentions Légales</a></li>
                <li><a href="#cookies" className='condition_a'>Politique de Cookies</a></li>
                <li><a href="#utilisation" className='condition_a'>Conditions d'Utilisation</a></li>
                <li><a href="#alcool" className='condition_a'>Clause d'Abus d'Alcool</a></li>
            </ul>
        </nav>
    </header>

        <section id="cgv" className='condition_section'>
            <h4>Conditions Générales de Vente (CGV)</h4>
            <p> Les présentes conditions générales de vente s'appliquent à toutes les commandes passées sur le site internet [www.erwantapparel.ch] par des consommateurs ou des professionnels. <br/> En passant commande sur le Site, le client accepte sans réserve les présentes CGV.</p>
            <p className='condition_point'>1. Identification de l'entreprise</p>
            <p>Nom de l'entreprise : Cave du Sonneur<br/>
            Adresse : Rue du Pressoir 32, 3960 Corin<br/>
            Email : vins@erwantapparel.ch<br/>
            Téléphone : +41 78 679 67 54</p>
            <p className='condition_point'>2. Produits et Services</p>
            <p>Les vins proposés à la vente sont décrits et présentés avec la plus grande précision possible. Cependant, des variations peuvent exister entre l'image du produit et le produit reçu. Les photographies et descriptions des produits n'ont pas de valeur contractuelle.</p>
            <p className='condition_point'>3. Prix </p>
            <p> Les prix des produits sont indiqués en francs suisses (CHF), toutes taxes comprises (TTC). Les frais de livraison ne sont pas inclus dans le prix indiqué et seront ajoutés au total de la commande lors du passage en caisse. Erwan Tapparel se réserve le droit de modifier ses prix à tout moment, mais les produits seront facturés sur la base des tarifs en vigueur au moment de la validation de la commande.</p>
            <p className='condition_point'>3. Commandes </p>
            <p> Pour passer commande, le client doit être âgé de 18 ans ou plus et disposer de la capacité juridique. Toute commande passée sur le Site constitue un contrat conclu à distance entre le client et Erwan Tapparel. Une fois la commande validée et le paiement effectué, un email de confirmation sera envoyé au client.</p>
        </section>

        <section id="confidentialite" className='condition_section'>
            <h4>Politique de Confidentialité</h4>
            <p>Nous nous engageons à protéger la confidentialité des données de nos utilisateurs</p>
            <p  className='condition_point'>1. Types de données collectées</p>
            <p>Nous collectons des informations telles que nom, adresse, email, numéro de téléphone, informations de commandes</p>
            <p  className='condition_point'>2. Finalité de la collecte</p>
            <p>Les données collectées sont utilisées pour traiter les commandes, fournir des services, et améliorer l'expérience utilisateur...</p>
          
        </section>

      
        <section id="mentions-legales" className='condition_section'>
            <h4>Mentions Légales</h4>
            <p  className='condition_point'>1. Éditeur du site</p>
            <p>Nom ou dénomination sociale : WebInBlocks - Tapparel Océane<br/>
            Adresse : Ruelle de Paris 3<br/>
            Téléphone : +41 78 815 77 85<br/>
            Email : oceane@webinblocks.ch</p>
            <p className='condition_point'>2. Hébergeur du site</p>
            <p>Nom de l’hébergeur : Infomaniak<br/>
            Adresse : Rue Eugène Marziano 25, 1227 Les Acacias (GE) </p>
        </section>

    
        <section id="cookies" className='condition_section'>
            <h4>Politique de Cookies</h4>
            <p>Nous utilisons des cookies pour améliorer l'expérience utilisateur sur notre site.</p>
            <p className='condition_point'>1. Types de cookies utilisés</p>
            <p>Nous utilisons des cookies fonctionnels, analytiques</p>
            <p className='condition_point'>2. Consentement des utilisateurs</p>
            <p>En utilisant notre site, vous consentez à l'utilisation de cookies</p>
           
        </section>

    
        <section id="utilisation" className='condition_section'>
            <h4>Conditions d'Utilisation du Site</h4>
            <p>Les présentes Conditions d'Utilisation régissent l'utilisation de notre site web...</p>
            <p className='condition_point'>1. Propriété intellectuelle</p>
            <p>Tout le contenu de ce site est protégé par les droits d'auteur...</p>
            <p className='condition_point'>2. Utilisation acceptable</p>
            <p>Vous acceptez de ne pas utiliser ce site pour des activités illégales ou frauduleuses...</p>
        </section>

     
        <section id="alcool" className='condition_section'>
            <h4>Clause d'Abus d'Alcool</h4>
            <p>La vente de boissons alcoolisées est réservée aux personnes majeures. L'abus d'alcool est dangereux pour la santé. À consommer avec modération.</p>
            <p className='condition_point'>1. Âge légal</p>
            <p>L'achat d'alcool est interdit aux mineurs. En passant commande, vous confirmez être âgé de 18 ans ou plus.</p>
        </section>

    <footer>
        <p>&copy; 2024 Erwan Tapparel. Tous droits réservés.</p>
    </footer>

       </div> 
)}