
import { TitleFacturationProcess } from '../components/ui/Utilities';

export default function OrderValid() {
  const savedData = localStorage.getItem('facturation_data');
 
  let parsedData = null;

  if (savedData) {
    try {
      parsedData = JSON.parse(savedData); // Essayez de parser savedData en JSON
    } catch (error) {
      console.error('Erreur lors de la conversion des données JSON :', error);
    }
  }


    return (
        <div style={{height:'100vh', display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center'}}>
          
            {parsedData.delivery === 'Cave'
               ? <TitleFacturationProcess
                    title="Nous vous remercions pour votre commande !"
                    subtitle="Nous vous contacteront prochainement pour venir récupérer votre commande " /> 

               : <TitleFacturationProcess
                    title="Nous vous remercions pour votre commande"
                    subtitle="Nous vous contacteront prochainement pour la livraison de votre commande."/>
            }
            <TitleFacturationProcess 
            subtitle=" PS : Possibilité de payer directement par twint ou 078 679 67 54 ou sur facture lors de la réception"
/>
        </div>
    )
}