import {AdminManage} from "../components/login/AdminManage";


/**
 * Screen for the Admin page. 
 */

export default function Administration() {
    return (
        <>
            <AdminManage />
           
        </>
    )
}