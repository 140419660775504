import { AddFacturationFormHome, SelectDelivery, AddFacturationFormCave } from "../components/order_process/FormFacturation";
import { useState } from 'react';

function FacturationDetails() {
  const [deliveryMode, setDeliveryMode] = useState('');

  const handleSelectDelivery = (value) => {
    setDeliveryMode(value);
  };


  return (
    <div>
      <SelectDelivery onSelectDelivery={handleSelectDelivery} />
      <div>
        {deliveryMode === 'cave' ? <AddFacturationFormCave /> : deliveryMode === 'domicile' ? <AddFacturationFormHome /> : ""}
      </div>
    </div>
  )
}


export default FacturationDetails;