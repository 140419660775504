import React from 'react';
import { useCart } from '../../context/CartContext';
import '../../styles/Basket.css';
import { useState } from 'react';
import { MdDelete } from "react-icons/md";
import { TitleFacturationProcess } from '../ui/Utilities';
import { NextStep } from '../ui/Utilities';
import { useEffect } from "react";


export const BasketIsNull = () => {
  return (
    <div className='center-container'>
      <TitleFacturationProcess title="Votre panier est vide" />
      <NextStep router='/cave/vins' title="Consulter les vins" btn_next_location="btn_basketIsNull" />
    </div>
  )
}

export const BasketIsNotNull = ({ showDelete, showCondition }) => {
  const { cart, setCart } = useCart([]);
  const [currentCart, setCurrentCart] = useState([cart]);
  const [total, setTotal] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);

  useEffect(() => {
    const cartFromStorage = localStorage.getItem('cart');
    if (cartFromStorage === null) {
      setCurrentCart([]);
      window.location.reload();
    } else {
      setCurrentCart(JSON.parse(cartFromStorage));
    }
}, []);

  const removeItem = (index) => {
    const updatedCart = [...currentCart];
    updatedCart.splice(index, 1);
    setCurrentCart(updatedCart);
    setCart(updatedCart); // Assurez-vous de mettre à jour le panier dans votre hook ou contexte
    localStorage.setItem('cart', JSON.stringify(updatedCart)); // Mettre à jour le localStorage
  };
  // Calculer le total lorsque le panier change
  useEffect(() => {
    const calculateTotal = () => {
      let sum = 0;
      cart.forEach((item) => {
        sum += item.price * item.quantity;
      });
      return sum;
    };

    const newTotal = calculateTotal();
    if (newTotal !== total) {
      setTotal(calculateTotal()); // Mettre à jour l'état total seulement si la valeur à changé
    }
  }, [cart, total]); // Dépend de la variable cart

  const handleDelivery = () => {
   
    const savedDataAll = localStorage.getItem('facturation_data');
    const savedData = JSON.parse(savedDataAll);

    if (!savedData) {
      setDeliveryCharge(1);
      return; // Sortir de la fonction si savedData est null ou undefined
  }
  
    if (savedData.delivery === "Cave") {
      setDeliveryCharge(0)
    }
    else if (savedData.delivery === "Domicile" && { total } >= 200) {
      setDeliveryCharge(0)
    } else {
      setDeliveryCharge(17)
    }

  const updatedSavedData = {
    ...savedData,
    deliveryCharge: deliveryCharge, // Ajouter deliveryCharge à savedData
  };

  localStorage.setItem('facturation_data', JSON.stringify(updatedSavedData));
  }
  useEffect(() => {
    handleDelivery();
  }, [total]); // Recalculer les frais de livraison lorsque le total change

  return (
    <div>
      {showDelete && <TitleFacturationProcess title="Mon panier" />}
      <div className="table_container">
        <table>
          <thead>
            <tr>
              <th> </th>
              <th> Produit </th>
              <th> Prix </th>
              <th> Quantité </th>
              <th> Sous - total </th>
              {showDelete && <th> Supprimer </th>}
            </tr>
          </thead>
          <tbody>
            {cart && cart.map((item, index) => (
              <tr key={index} className="cart-item">
                <td> <img src={item.image} alt={item.variety} className="img_bottle"></img></td>
                <td> {item.variety} - {item.millesime} </td>
                <td> {item.price} .- </td>
                <td> {item.quantity} </td>
                <td> {item.price * item.quantity} .- </td>
                {showDelete && <td> <span className="delete-icon" onClick={() => removeItem(index)}><MdDelete /></span> </td>}
              </tr>
            ))}
            <tr>
              <td colSpan={4} className="no-border-top">Participation au frais de port : 
           
             {showCondition && <span className="info_supp"> <br/> Gratuit si retrait à la cave, livraison à domicile gratuite dès <strong> CHF 200.- d'achat </strong> </span> }
                 </td> 
              {deliveryCharge === 0 && <td className="delivery-column">gratuit </td>}
              {deliveryCharge === 17 && <td className="delivery-column">CHF 17 .- </td>}
              {deliveryCharge === 1 && <td className="delivery-column">Calculé à la prochaine étape </td>}   
            </tr>


         
            <tr>
              <td colSpan={4} className="no-border-top">Total :</td>
              <td className="total-column"> CHF {total + (deliveryCharge > 1 ? deliveryCharge:0)} .- </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
