import ManageQrCode from "../components/qr-code/DisplayQrCode";

/**
 * Screen for the QrCode page. 
 */

export default function QrCode() {
    return (
        <>
        <ManageQrCode/>
        </>
    )
}