import { useEffect, useState } from "react";
import '../../styles/Facturation_details.css'
import { useNavigate } from 'react-router-dom';
import { BtnSubmit } from '../ui/Utilities';
import FilteredButtons from "../ui/FilteredButtons";
import { TitleFacturationProcess } from '../ui/Utilities';

export const SelectDelivery = ({ onSelectDelivery }) => {

    const [selectDelivery, setSelectDelivery] = useState('');
    const [showAutorisation, setShowAutorisation] = useState(true);
    const navigate = useNavigate();

    const deliveryItems = [
        { label: 'Livraison à domicile', value: "domicile" },
        { label: 'Retrait à la cave', value: "cave" },
    ];

    const handleSelectDelivery = (value) => {
        setSelectDelivery(value);
        onSelectDelivery(value);
    }

    return (
<>
            {showAutorisation && <div className="popup_autorisation_overlay">
            <div className="popup_autorisation">
                <div className="popup_autorisation_content1">
                    <p className="popup_autorisation_title"> Avez-vous plus de 16 ans ? </p>
                   
                    <button onClick={() => setShowAutorisation(false)} className="popup_autorisation_btn">Oui</button>
                    <button onClick={() =>  navigate('/cave')} className="popup_autorisation_btn"> Non, retourner à l'accueil</button>
                    <p className="popup-subtitle"> Vous devez avec l'âge légal de consommer de l'alcool pour commander </p>
                </div>
            </div>
        </div>

            }


            <TitleFacturationProcess
                title="Détails de livraison"
                subtitle="Sélectionnez la façon dont vous souhaitez recevoir votre livraison." />

            <FilteredButtons
                items={deliveryItems}
                setSelected={handleSelectDelivery}
                selectedValue={selectDelivery}
            />
            </>
    );
}

const GenericFacturationForm = ({ initialFields, onSubmitForm, additionalFields }) => {
    const [formFields, setFormFields] = useState(initialFields);
    const navigate = useNavigate();

    useEffect(() => {
        const savedData = localStorage.getItem('facturation_data');
        if (savedData) {
            const parsedData = JSON.parse(savedData);
            // Merge initial fields with saved data to ensure all fields are present
            setFormFields((prevFields) => ({
                ...prevFields,
                ...parsedData,
                delivery: initialFields.delivery // Ensure the delivery field is always updated
            }));
        }
    }, [initialFields]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormFields((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        localStorage.setItem('facturation_data', JSON.stringify(formFields));
        try {
            await onSubmitForm(formFields);
            navigate('/cave/panier/details-commande');

        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className='container'>
            <form onSubmit={handleSubmit}>
                <div className="div_coordonnee">Entrez vos coordonnées</div>
                <div className="lbl_space_facturationDetails">
                    <div className="field">
                        <label htmlFor="firstname" className="label_facturationDetails">Prénom *</label>
                        <input
                            className="input_display"
                            type="text"
                            name="firstname"
                            value={formFields.firstname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="lastname" className="label_facturationDetails">Nom *</label>
                        <input
                            className="input_display"
                            type="text"
                            name="lastname"
                            value={formFields.lastname}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="lbl_space_facturationDetails">
                    <div className="field">
                        <label htmlFor="telefonNumber" className="label_facturationDetails">Numéro de téléphone *</label>
                        <input
                            className="input_size"
                            type="text"
                            name="telefonNumber"
                            value={formFields.telefonNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="lbl_space_facturationDetails">
                    <div className="field">
                        <label htmlFor="email" className="label_facturationDetails">Adresse de messagerie *</label>
                        <input
                            className="input_size"
                            type="email"
                            name="email"
                            value={formFields.email}
                            onChange={handleChange}
                        />
                    </div>
                </div>
             


                {additionalFields.map(({ label, name, type, required }) => (
                    <div className="lbl_space_facturationDetails">
                        <div className="field" key={name.id}>
                            <label htmlFor={name} className="label_facturationDetails">{label}</label>
                            <input
                                className="input_size"
                                type={type}
                                name={name}
                                value={formFields[name]}
                                onChange={handleChange}
                                required={required}
                            />
                        </div>
                    </div>

                ))}
                <BtnSubmit title="Continuer" />
            </form>
        </div>
    );
};

export const AddFacturationFormCave = () => {
    const initialFields = {
        firstname: '',
        lastname: '',
        telefonNumber: '',
        email: '',
        delivery: 'Cave',
    };


    const onSubmitForm = async (formData) => {
        localStorage.setItem('facturation_data', JSON.stringify(formData));
    }

    
    return <GenericFacturationForm 
        initialFields={initialFields}
        onSubmitForm={onSubmitForm}
        additionalFields={[]} />;
};

export const AddFacturationFormHome = () => {
    const initialFields = {
        firstname: '',
        lastname: '',
        telefonNumber: '',
        email: '',
        society: '',
        address: '',
        NPA: '',
        city: '',
        delivery: 'Domicile'
    };

    const additionalFields = [
        { label: 'Nom de l\'entreprise', name: 'society', type: 'text', required: false },
        { label: 'Nom et numéro de rue *', name: 'address', type: 'text', required: true },
        { label: 'Code postale *', name: 'NPA', type: 'number', required: true },
        { label: 'Ville *', name: 'city', type: 'text', required: true },
        { label: 'Informations complémentaires', name: 'complements', type:'text', required:false}
    ];

    const onSubmitForm = async (formData) => {
        localStorage.setItem('facturation_data', JSON.stringify(formData));

    }
 
    return <GenericFacturationForm
        initialFields={initialFields}
        onSubmitForm={onSubmitForm}
        additionalFields={additionalFields} />;
}