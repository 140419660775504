import { useEffect, useState } from "react";
import { db, storage } from "../../../config/InitFirebase";
import { addDoc, collection, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import '../../styles/FormManage.css';

const carouselImagesCollectionRef = collection(db, "images");

export const DeleteImageForm = ({ image }) => {
    const deleteImage = async (e) => {
        e.preventDefault();
        const ImageDoc = doc(db, "images", image.id);
        await deleteDoc(ImageDoc);
        alert("L'image à été supprimé")
    };

    return (
        <div className="btn_container">
            <button type="submit" onClick={deleteImage} className="btn_manage">Supprimer la sélection</button>
        </div>
    )
}


export const UdpateImageForm = ({ image }, { onFormClose }) => {
    const [updatedImage, setUpdatedImage] = useState(null);
    const [updatedLegend, setUpdatedLegend] = useState(image.legend);
    const [updatedText, setUpdatedText] = useState(image.text)

    useEffect(() => {
        setUpdatedImage(image.imageURL);
        setUpdatedLegend(image.legend)
        setUpdatedText(image.text)
    }, [image]);

    const updateImage = async (e) => {
        e.preventDefault();
        const ImagesDoc = doc(db, "images", image.id);
        let imageURL = image.imageURL;

        if (updatedImage && updatedImage !== image.imageURL) {
            const filesFolderRef = ref(storage, `carousel/${updatedImage.name}`);
            try {
                await uploadBytes(filesFolderRef, updatedImage);
                imageURL = await getDownloadURL(filesFolderRef);
            } catch (err) {
                console.error(err);
            }
        }
        try {
            await updateDoc(ImagesDoc, {
                imageURL: imageURL,
                legend: updatedLegend,
                text: updatedText,
            });
            alert("L'image à été modifié")
            onFormClose();
        } catch (err) {
            console.error(err);
        }
    };



    return (
        <form onSubmit={(updateImage)}>

            <div className="lbl_space">
                <label className="label_manage">Image : </label>
                <input className="input_space"
                    type="file"
                    value={updateImage}
                    onChange={(e) => setUpdatedImage(e.target.files[0])}
                />
            </div>
            <div className="lbl_space">
                <label className="label_manage">Partie concerné : </label>
                <select className="input_space" onChange={(e) => setUpdatedLegend(e.target.value)}>
                    <option value={updatedLegend} />
                    <option value="Histoire">Histoire</option>
                    <option value="background">Bandeau déroulant</option>
                    <option value="team">Equipe</option>
                </select>
            </div>
            <div className="lbl_space">
                <label  className="label_manage">Text : </label>
                <input className="input_space"
                    type="text"
                    value={updatedText}
                    onChange={(e) => setUpdatedText(e.target.value)}
                />
            </div>
            <div className="btn_container"> 
            <button type="submit" className="btn_manage">Mettre à jour l'image</button>
        </div>
        </form>

    )
};

export const AddImageForm = ({ onFormClose }) => {

    const [newImageURL, setNewImageURL] = useState(null);
    const [newLegend, setNewLegend] = useState(null);
    const [newText, setNewText] = useState(null);


    const onSubmitEvent = async (e) => {
        e.preventDefault(); // Empêche le rechargement de la page
        let imageURL = ' ';


        if (newImageURL) {
            const filesFolderRef = ref(storage, `carousel/${newImageURL.name}`);
            try {
                await uploadBytes(filesFolderRef, newImageURL);
                imageURL = await getDownloadURL(filesFolderRef);
            } catch (err) {
                console.error(err);
            }
        }
        try {
            await addDoc(carouselImagesCollectionRef, {
                imageURL: imageURL,
                legend: newLegend,
                text: newText
            });

            alert("L'image à été ajouté")
            onFormClose(); // Ferme le formulaire après l'ajout
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <form onSubmit={onSubmitEvent}>
            <div className="lbl_space">
                <label className="label_manage">Image : </label>
                <input className="input_space"
                    type="file"
                    onChange={(e) => setNewImageURL(e.target.files[0])}
                />
            </div>
            <div className="lbl_space">
            <label className="label_manage">Partie concerné : </label>
                <select className="input_space" onChange={(e) => setNewLegend(e.target.value)}>
                    <option value=""> Sélectionner l'onglet </option>
                    <option value="musique">Musique</option>
                    <option value="background">Vin</option>
                    <option value="team">Equipe</option>
                </select>
              </div>

            <div className="lbl_space">
                <label  className="label_manage">Texte (blague) : </label>
                <input className="input_space"
                    type="text"
                    onChange={(e) => setNewText(e.target.value)}
                />
            </div>
            <div className="btn_container"> 
            <button type="submit" className="btn_manage">Ajouter l'image</button>
            </div>
        </form>
    )
};

//------------------------------