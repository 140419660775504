import { BasketIsNotNull } from './Basket';
import '../../styles/Summary_order.css';
import { NextStep, TitleFacturationProcess } from '../ui/Utilities';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import { AddOrUpdateClient, AddOrder } from './OrderManage';
import { useForm } from 'react-hook-form';

//Adresse de facturation + bouton modifier navigate('/details-facturation');
export const DisplayFacturation = () => {
    const facturation_data = JSON.parse(localStorage.getItem('facturation_data'));

    const Manage_delivery_information = () => {
        if (facturation_data.NPA !== null) {
            return (
                <div>
                    <p> <strong> Informations de livraisons  </strong> </p>
                    <div className="info_row">
                        <div className="label">Adresse :</div>
                        <div className="value">{facturation_data.adress || "non renseignée"} </div>
                    </div>
                    <div className="info_row">
                        <div className="label">Ville :</div>
                        <div className="value">{facturation_data.city || "non renseignée"} </div>
                    </div>
                    <div className="info_row">
                        <div className="label">NPA :</div>
                        <div className="value">{facturation_data.NPA || "non renseignée"} </div>
                    </div>
                    <div className="info_row">
                        <div className="label">Entreprise :</div>
                        <div className="value">{facturation_data.society || "non renseignée"} </div>
                    </div>

                </div>
            )
        }
    }

    return (
        <div>
            <TitleFacturationProcess
                title="Détails de facturation"
                subtitle="Informations de facturation et livraison" />
            {facturation_data ? (
                <div className='content_display'>

                    <div className="info_section">
                        <div className="personal_info">
                            <p> <strong>Information personnelles </strong></p>
                            <div className="info_row">
                                <div className="label">Prénom :</div>
                                <div className="value">{facturation_data.firstname}</div>
                            </div>
                            <div className="info_row">
                                <div className="label">Nom :</div>
                                <div className="value">{facturation_data.lastname}</div>
                            </div>
                            <div className="info_row">
                                <div className="label">Email :</div>
                                <div className="value">{facturation_data.email}</div>
                            </div>
                            <div className="info_row">
                                <div className="label">Téléphone :</div>
                                <div className="value">{facturation_data.telefonNumber}</div>
                            </div>

                        </div>
                        <Manage_delivery_information />
                        <div>
                        </div>
                    </div>

                    <NextStep router="/cave/panier/details-facturation" title="Modifier les informations" btn_next_location="btn_location">  </NextStep>
                </div>

            ) : (
                <p>Aucune donnée de facturation disponible.</p>
            )}
        </div>
    );
};

//Résumé de la commande + bouton modifier
export const Recap_commande = () => {
    const navigate = useNavigate();

    const [currentCart, setCurrentCart] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState({
        showAdditionalInfo: false,
        additionalInfo: '',
        dataConsent: false,
    });
  const { register, handleSubmit, formState: { errors } } = useForm();

    const baseURL = process.env.NODE_ENV === 'production'
        ? 'https://erwantapparel.ch'
        : 'http://localhost:5000';

    const api = axios.create({
        baseURL: baseURL,
        headers: {
            'Content-Type': 'application/json'
        }
    })

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setAdditionalInfo({
            ...additionalInfo,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleAddClient = async (data) => {
 

        try {

            const orderId = await AddOrder();
            const clientId = await AddOrUpdateClient(orderId)

            const facturation_data = JSON.parse(localStorage.getItem('facturation_data'));
            const order_data = JSON.parse(localStorage.getItem('cart'));

            const localStorageData = {
                email: facturation_data.email, // Email du client
                delivery: facturation_data.delivery, // Moyen de livraison
                firstname: facturation_data.firstname,
                lastname: facturation_data.lastname,
                telefonNumber: facturation_data.telefonNumber,
                deliveryCharge: facturation_data.deliveryCharge,
                NPA: facturation_data.NPA,
                address: facturation_data.address,
                city: facturation_data.city,
                complement: additionalInfo.additionalInfo

            };

            const orderData = order_data.map(wine => ({
                variety: wine.variety,
                millesime: wine.millesime,
                price: wine.price,
                quantity: wine.quantity,
            }));


            const response = await api.post('https://erwantapparel.ch/backend/send_email.php', {
                localStorageData,
                orderData
            });


            /*
            //ajout de la commande dans firestore et récupère l'id Order pour le stocker dans chez le client afin de gérer l'historique de commande
            const orderId = await AddOrder();
            const clientId = await AddOrUpdateClient(orderId)

            const response = await api.post('/backend/send_email',  {
                clientId, // Envoyer l'ID du client au backend
                orderId,
            });
*/
            /*
            // Appeler le backend pour envoyer l'email
            await api.post('/backend/send-email', {
                clientId, // Envoyer l'id du client au backend
                orderId,
            });
            */
            localStorage.removeItem('cart')
            setCurrentCart([]);
            navigate('/cave/panier/valider-commande');

        } catch (error) {
            console.log("Error", error)
        };

    };
    return (
        <>
            <BasketIsNotNull showDelete={false} />

            <form onSubmit={handleSubmit(handleAddClient)}>
            <div className="checkbox_informations">
                <label htmlFor="showAdditionalInfo">
                    <input
                        className='check_information'
                        type="checkbox"
                        name="showAdditionalInfo"
                        checked={additionalInfo.showAdditionalInfo}
                        onChange={handleChange}
                    />
                    Ajouter des informations supplémentaires concernant la livraison
                </label>
            </div>

            {additionalInfo.showAdditionalInfo && (
                <div className="checkbox_informations">
                    <textarea
                        className="input_additionalInfo"
                        name="additionalInfo"
                        value={additionalInfo.additionalInfo}
                        onChange={handleChange}
                    />
                </div>
            )}

            <div className="checkbox_informations">
                <label htmlFor="dataConsent" >
                    <input
                        className='check_information'
                        type="checkbox"
                        name="dataConsent"
                        {...register('dataConsent', {
                            required: {
                                value: true,
                                message: "Merci d'accepter les conditions",
                            },
                        })}


                        checked={additionalInfo.dataConsent}
                        onChange={handleChange}
                    />
                    J'accepte que mes données soient stockées afin de traiter la commande
                </label>
            </div>
            {errors.dataConsent && <p className="error_message">{errors.dataConsent.message}</p>} {/* Affiche le message d'erreur */}
            <p className="facture_pay"> La facture vous sera remis lors du retrait</p>

            <div className="btn_content">
                <button type='submit' className="btn_next"> Valider la commande </button>
            </div>
            </form>
        </>

    )
}
