import "../../styles/Login.css";
import { auth } from "../../../config/InitFirebase";
import {
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

export const LoginDisplay = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate();

    console.log(auth?.currentUser?.email)

    const login = async (e) => {
      e.preventDefault();
        try {
          await signInWithEmailAndPassword(auth, email, password);
          console.log("User logged in Successfully");
          const user = auth.currentUser;
          console.log(user);
          alert("Connexion réussi")
          navigate('/administration');
    
        } catch (err) {
          console.error(err);
          alert("Erreur lors de la connexion");
        }
      };

      const logout = async () => {
        try {
          await signOut(auth);
          alert("Vous avez été déconnecté")
        } catch (err) {
          console.error(err);
        }
      };

    return (
        <div>
        <form>
          <h1>Connexion</h1>
         
          <input className="login_input"
            type="email"
            placeholder="Email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input className="login_input"
            type="password"
            name="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
         <br/>
          <button onClick={login} className="btn_next">Se connecter</button>
          <button onClick={logout} className="btn_next">Se déconnecter</button>
        </form>
      </div>
    );
}