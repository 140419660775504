import { getDocs} from "firebase/firestore";
import { db } from "../../config/InitFirebase"; // Assurez-vous que le chemin est correct
import { collection, onSnapshot } from "firebase/firestore";

const winesCollectionRef = collection(db, "vins");
const eventsCollectionRef = collection(db, "events");
const facturation_detailsCollectionRef = collection(db, "clients");
const carouselImagesCollectionRef = collection(db, "images");
 
//se code faire une requête à firestore à chaque fois 
export const getWineList = async () => {
    try {
        const data = await getDocs(winesCollectionRef);
        const filteredData = data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        return filteredData;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

//-------------------------------GET Wine-------------------------------
//écouter la mise à jour en temps réel et éviter de requêter à chaque fois la base de données firestore. 
//Cela permettra d'éviter d'avoir trop de requête et dépasser les quotas firestore
//onSnapshot écoute les mises à jour en temps réel 
//stocke dans le cache les infos et les change que quand il y a un changement dans firestore. On arrête de requêter à chaque fois la page
export const listenToWineList = (callback) => {
    return onSnapshot(winesCollectionRef, (snapshot) => {
        const wineList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        callback(wineList);
    });
};

//GET EVENTS
export const listenToEventList = (callback) => {
    return onSnapshot(eventsCollectionRef, (snapshot) => {
        const eventList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        callback(eventList);
    });
};

//GET DATA CLIENTS
export const listenToClientList = (callback) => {
    return onSnapshot(facturation_detailsCollectionRef, (snapshot) => {
        const clientsList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        callback(clientsList);
    });
};
export const listenToCarouselImages = (callback) => {
    return onSnapshot(carouselImagesCollectionRef, (snapshot) => {
        const carouselImagesList = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        callback(carouselImagesList);
    });
};