import { Presentation, HomeSection } from "../components/MusicContainer"
import { MobileAlert } from "../components/Construction"
import { motion } from 'framer-motion';
import { ScrollToTopButton } from "../components/ui/Buttons";

export default function Music () {

    const pageTransition = {
        initial: { opacity: 0, y: 20, },
        animate: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeInOut", }, },
        exit: { opacity: 0, y: -20, transition: { duration: 0.3, ease: "easeInOut", }, },
    };

    return(
        <div>
                <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={pageTransition}>
   
            <Presentation/>
            <HomeSection/>
            <ScrollToTopButton/>
            </motion.div>
        </div>
       
    )
}