import { DisplayFacturation, Recap_commande } from "../components/order_process/SummaryOrder";

/**
 * Screen for the Order Details page
 */

export default function OrderDetails() {
    return (
        <>
            <DisplayFacturation />
            <Recap_commande />
        </>
    )
}