import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBa-t9OWobkVlQL79jvqHac2NyXUnIIGkg",
  authDomain: "vins-erwan-tapparel.firebaseapp.com",
  projectId: "vins-erwan-tapparel",
  storageBucket: "vins-erwan-tapparel.appspot.com",
  messagingSenderId: "438869993223",
  appId: "1:438869993223:web:cb7961c0a8a1f15e3e87a2",
  measurementId: "G-FZNE74HC6Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export {ref, getDownloadURL};
