import { useEffect, useState } from "react";
import { AddWineForm, UdpateWineForm, DeleteWineForm } from "../wines/WineManage";
import { AddImageForm, UdpateImageForm, DeleteImageForm } from "../home/HomeManage";
import '../../styles/AdminManage.css';
import { listenToCarouselImages, listenToWineList } from "../GetServices";



export const AdminManage = () => {
    const [showImageForm, setShowImageForm] = useState(false);
    const [showWineForm, setShowWineForm] = useState(false);
    const [showOrderForm, setShowOrderForm] = useState(false);
    const [clickedButton, setClickedButton] = useState(null);

    const handleImageClick = (buttonName) => {
        setShowImageForm(true);
        setShowOrderForm(false);
        setShowWineForm(false)
        setClickedButton(buttonName);
    };

    const handleWineClick = (buttonName) => {
        setShowWineForm(true);
        setShowOrderForm(false);
        setShowImageForm(false);
        setClickedButton(buttonName);
    };

    const handleOrderClick = (buttonName) => {
        setShowOrderForm(true);
        setShowImageForm(false);
        setShowWineForm(false);
        setClickedButton(buttonName);
    };

    return (
        <div className="div_content_admin">
            <div className="admin_panel">
                <h3 className="h3_admin"> Administration </h3>
                <ul>
                    <li>
                        <button onClick={() => handleWineClick("Vins")}
                            className={`btn_admin ${clickedButton === "Vins" ? 'clicked' : ''}`}>Vins</button>
                    </li>
                    <li>
                        <button onClick={() => handleImageClick("Images")}
                            className={`btn_admin ${clickedButton === "Images" ? 'clicked' : ''}`}
                        >Images</button>
                    </li>
                    <li>
                        <button onClick={() => handleOrderClick("Commandes")}
                            className={`btn_admin ${clickedButton === "Commandes" ? 'clicked' : ''}`}
                        >Commandes</button>
                    </li>
                </ul>
            </div>
            <div className="admin_content">
                {showWineForm && <DisplayWineForm title="Gestion des vins" />
                }

                {showImageForm && <DisplayImageForm title="Gestion des images" />
                }

                {showOrderForm && <DisplayOrderForm title="Gestion des commandes" />
                }
            </div>

        </div>
    )
}

const SelectInformation = ({
    label,
    handleSelect,
    value,
    elementToSelected,
    elementToSelected2,
    elementList
}) => {
    return (
        <div className="select_container">
            <label className="select_manage">Sélectionner {label} </label>
            <select
                onChange={handleSelect}
                value={value || ' '}
                className="select_input_manage"
            >

                {elementList.map((e) => (
                    <option key={e.id} value={e[elementToSelected]}>
                        {e[elementToSelected]} {e[elementToSelected2]}
                    </option>
                ))}
            </select>
        </div>
    );
};

export const DisplayWineForm = ({ title }) => {

    const [wineList, setWineList] = useState([]);
    const [selectedWine, setSelectedWine] = useState(null);

    const [showAddWineForm, setShowAddWineForm] = useState(false);
    const [showUpgradeWineForm, setShowUpgradeWineForm] = useState(false);
    const [showDeleteWineForm, setShowDeleteWineForm] = useState(false);

    const [clickedButton, setClickedButton] = useState(null);

    useEffect(() => {
        const unsubscribe = listenToWineList((data) => {
            setWineList(data);
            if (data.length > 0) {
                setSelectedWine(data[0]); //Sélectionner par défaut le premier vin de la liste
            }
        });
        //unsubscribe permet d'éviter les fuites de mémoire
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);


    const handleAddWineClick = (buttonName) => {
        setShowAddWineForm(true);
        setShowUpgradeWineForm(false);
        setShowDeleteWineForm(false);
        setClickedButton(buttonName);
    };

    const handleUpgradeWineClick = (buttonName) => {
        setShowUpgradeWineForm(true);
        setShowAddWineForm(false);
        setShowDeleteWineForm(false);
        setClickedButton(buttonName);
    };


    const handleDeleteWineClick = (buttonName) => {
        setShowDeleteWineForm(true);
        setShowUpgradeWineForm(false);
        setShowAddWineForm(false);
        setClickedButton(buttonName);

    };

    const handleWineSelect = (e) => {
        const selectedWine = wineList.find(wine => wine.variety === e.target.value);
        setSelectedWine(selectedWine);
    };

    const handleFormClose = () => {
        setShowAddWineForm(false);
        setShowUpgradeWineForm(false);
        setShowDeleteWineForm(false);

    };

    return (
        <div className="tab_display_admin">
            <h3> {title} </h3>
            <button onClick={() => handleAddWineClick("Ajouter")} className={`btn_admin_2 ${clickedButton === "Ajouter" ? 'clicked' : ''}`}> Ajouter </button>
            <button onClick={() => handleUpgradeWineClick("Modifier")} className={`btn_admin_2 ${clickedButton === "Modifier" ? 'clicked' : ''}`}> Modifier </button>
            <button onClick={() => handleDeleteWineClick("Supprimer")} className={`btn_admin_2 ${clickedButton === "Supprimer" ? 'clicked' : ''}`}> Supprimer </button>

            <div>
                {showAddWineForm &&
                    (
                        <div className="form_admin">
                            <AddWineForm onFormClose={handleFormClose} />
                        </div>
                    )}
            </div>
            <div>
                {showUpgradeWineForm && (
                    <div className="form_admin">
                        <SelectInformation
                            label="le vin"
                            handleSelect={handleWineSelect}
                            value={selectedWine?.variety}
                            elementToSelected={"variety"}
                            elementToSelected2={"millesime"}
                            elementList={wineList}
                        />
                        {selectedWine && <UdpateWineForm wine={selectedWine} />}
                    </div>
                )}
            </div>

            <div>
                {showDeleteWineForm && (
                    <div className="form_admin">
                        <SelectInformation
                            label="le vin"
                            handleSelect={handleWineSelect}
                            value={selectedWine?.variety}
                            elementToSelected={"variety"}
                            elementToSelected2={"millesime"}
                            elementList={wineList}
                        />
                        {selectedWine && <DeleteWineForm wine={selectedWine} />}
                    </div>
                )}
            </div>
        </div>
    );
}
export const DisplayImageForm = ({ title }) => {

    const [imageList, setImageList] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const [showAddImageForm, setShowAddImageForm] = useState(false);
    const [showUpgradeImageForm, setShowUpgradeImageForm] = useState(false);
    const [showDeleteImageForm, setShowDeleteImageForm] = useState(false);

    const [clickedButton, setClickedButton] = useState(null);

    useEffect(() => {
        const unsubscribe = listenToCarouselImages((data) => {
            setImageList(data);
            if (data.length > 0) {
                setSelectedImage(data[0]); //Sélectionner par défaut le premier vin de la liste
            }
        });
        //unsubscribe permet d'éviter les fuites de mémoire
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);


    const handleAddImageClick = (buttonName) => {
        setShowAddImageForm(true);
        setShowUpgradeImageForm(false);
        setShowDeleteImageForm(false);
        setClickedButton(buttonName);
    };

    const handleUpgradeImageClick = (buttonName) => {
        setShowUpgradeImageForm(true);
        setShowAddImageForm(false);
        setShowDeleteImageForm(false);
        setClickedButton(buttonName);
    };


    const handleDeleteImageClick = (buttonName) => {
        setShowDeleteImageForm(true);
        setShowUpgradeImageForm(false);
        setShowAddImageForm(false);
        setClickedButton(buttonName);

    };

    const handleImageSelect = (e) => {
        const selectImage = imageList.find(image => image.text === e.target.value);
        console.log(selectImage)
        setSelectedImage(selectImage);
    };

    const handleFormClose = () => {
        setShowAddImageForm(false);
        setShowUpgradeImageForm(false);
        setShowDeleteImageForm(false);

    };

    return (
        <div className="tab_display_admin">
            <h3> {title} </h3>
            <button onClick={() => handleAddImageClick("Ajouter")} className={`btn_admin_2 ${clickedButton === "Ajouter" ? 'clicked' : ''}`}> Ajouter </button>
            <button onClick={() => handleUpgradeImageClick("Modifier")} className={`btn_admin_2 ${clickedButton === "Modifier" ? 'clicked' : ''}`}> Modifier </button>
            <button onClick={() => handleDeleteImageClick("Supprimer")} className={`btn_admin_2 ${clickedButton === "Supprimer" ? 'clicked' : ''}`}> Supprimer </button>

            <div>
                {showAddImageForm &&
                    (
                        <div className="form_admin">
                            <AddImageForm onFormClose={handleFormClose} />
                        </div>
                    )}
            </div>
            <div>
                {showUpgradeImageForm && (
                    <div className="form_admin">
                        <SelectInformation
                            label="l'image"
                            handleSelect={handleImageSelect}
                            value={selectedImage?.text}
                            elementToSelected={"text"}
                            elementToSelected2={"legend"}
                            elementList={imageList}
                        />
                        {selectedImage && <UdpateImageForm image={selectedImage} />}
                    </div>
                )}
            </div>

            <div>
                {showDeleteImageForm && (
                    <div className="form_admin">
                        <SelectInformation
                            label="images"
                            handleSelect={handleImageSelect}
                            value={selectedImage?.text}
                            elementToSelected={"text"}
                            elementToSelected2={"legend"}
                            elementList={imageList}
                        />
                        {selectedImage && <DeleteImageForm image={selectedImage} />}
                    </div>
                )}
            </div>
        </div>
    );
}

export const DisplayOrderForm = ({ title }) => {

    const [orderList, setOrderList] = useState([]);
  

    const [showOrderToProcessedForm, setShowOrderToProcessedForm] = useState(false);
    const [showOrderToDeliveredForm, setShowOrderToDeliveredForm] = useState(false);
    const [showOrderToCompletedForm, setShowOrderToCompletedForm] = useState(false);

    const [clickedButton, setClickedButton] = useState(null);

    useEffect(() => {
        const unsubscribe = listenToWineList((data) => {
            setOrderList(data);
           
        });
        //unsubscribe permet d'éviter les fuites de mémoire
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);


    const handleOrderToProcessedClick = (buttonName) => {
        setShowOrderToProcessedForm(true);
        setShowOrderToDeliveredForm(false);
        setShowOrderToCompletedForm(false);
        setClickedButton(buttonName);
    };

    const handleOrderToDeliveredClick = (buttonName) => {
        setShowOrderToDeliveredForm(true);
        setShowOrderToProcessedForm(false);
        setShowOrderToCompletedForm(false);
        setClickedButton(buttonName);
    };


    const handleOrderToCompletedClick = (buttonName) => {
        setShowOrderToCompletedForm(true);
        setShowOrderToProcessedForm(false);
        setShowOrderToDeliveredForm(false);
        setClickedButton(buttonName);

    };

    return (
        <div className="tab_display_admin">
            <h3> {title} </h3>
            <button onClick={() => handleOrderToProcessedClick("Commandes à traiter")} className={`btn_admin_2 ${clickedButton === "Commandes à traiter" ? 'clicked' : ''}`}> Commandes à traiter </button>
            <button onClick={() => handleOrderToDeliveredClick("Commandes à livrer")} className={`btn_admin_2 ${clickedButton === "Commandes à livrer" ? 'clicked' : ''}`}> Commandes à livrer </button>
            <button onClick={() => handleOrderToCompletedClick("Commandes terminées")} className={`btn_admin_2 ${clickedButton === "Commandes terminées" ? 'clicked' : ''}`}> Commandes terminées </button>
</div>
)
}