import '../../styles/titles.css'

export const MainTitle = ({title}) => {
    return(
    <h1 className="music_title_main"> {title} </h1>
    )
}

export const Subtitle = ({subtitle}) => {
    return(
    <h3 className="music_subtitle_main"> {subtitle} </h3>
    )
}