import '../styles/construction.css'; // Importez le fichier CSS pour le style

export const Construction = () => {
  return (
    <div className="construction-page">
      <div className="construction-content">
        <h1>Page en Construction</h1>
        <p>Nous travaillons dur pour vous offrir une meilleure expérience. <br/> Revenez bientôt !</p>
      </div>
    </div>
  );
};

export const MobileAlert = () => {
  return (
    <div className="mobile-alert">
      <p>Nous travaillons sur l'adaptation mobile de cette page. <br/> <br/> Merci de votre patience !</p>
    </div>
  );
};

