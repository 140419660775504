import { Map } from "../../components/Map";
import { ProjectPresentation } from "../../components/ui/ProjectPresentation";
import font_bisse from '../../assets/bisse.jpg'
import { MobileAlert } from "../../components/Construction"

export const Bisses = () => {
    const bisseLocations = [
        { lat: 46.3183355, lng: 7.7774120, name: 'Bisse de Stägeru', videoUrl: 'https://www.youtube.com/embed/7clb-fVrScQ?si=onol_h00FYTNV54C' },
        { lat: 46.269703, lng: 7.534170, name: 'Bisse de Briey', videoUrl: 'https://www.youtube.com/embed/p7nq8Kb7DZw?si=e0MMFX-Gr_E8po9A' },
        { lat: 46.198941, lng: 7.358296, name: 'Grand bisse de Vex', videoUrl: '"https://www.youtube.com/embed/vYbl0vIYrCg?si=Z-nBQpImlsB8D1iC"' },
        { lat: 46.261993, lng: 7.432681, name: 'Bisse du Sillonin', videoUrl: 'https://www.youtube.com/embed/uWKkXIIaDKU?si=jDl12wOlR31Z6MOP' },
        { lat: 46.3009658, lng: 7.4005801, name: 'bisse de Sion', videoUrl: 'https://www.youtube.com/embed/P5YUOSv1ZHY?si=GPHWOHQZ2IBtUTql' },
        { lat: 46.318671, lng: 7.880657, name: 'bisse de Laldneri', videoUrl: 'https://www.youtube.com/embed/SvjTQhuC9h8?si=WzBWuhh-N-al4Zse' },
        { lat: 46.29, lng: 7.46, name: 'bisse des Miriouges', videoUrl: 'https://www.youtube.com/embed/cuB2LxJsvIA?si=0_cmoC3sMSNpZHhK' },
        { lat: 46.199467, lng: 7.404139, name: 'Bisse de fang', videoUrl: 'https://www.youtube.com/embed/C-bVoW2SKds?si=Agx_vuL8EkCaNKsU' },
        { lat: 46.34, lng: 7.52, name: 'Bisse de Tsittoret', videoUrl: 'https://www.youtube.com/embed/ky0Szn3gQN0?si=A-Azu-5lTqYnTuZq' },
        { lat: 46.325655, lng: 7.551256, name: 'bisse de Varen', videoUrl: 'https://www.youtube.com/embed/HLfJDuHnSgo?si=Xzl1gFp__f3kY_iv' },
        { lat: 46.300050, lng: 7.317987, name: 'Bisse de la Tsandra', videoUrl: 'https://www.youtube.com/embed/UBhFYK-r5FE?si=l3e7fFjTzwSOgE2K' },
        { lat: 46.272731, lng: 7.533110, name: 'Bisse de Ricard', videoUrl: 'https://www.youtube.com/embed/7Lmp0PMBiWw?si=4ixkebEN7KxX9JEV' },
        { lat: 46.209846, lng: 7.349172, name: 'Bisse de Baar', videoUrl: 'https://www.youtube.com/embed/8nXEdHIDZA4?si=ANUxNMTzB1PRXMOQ' },
        { lat: 46.283696, lng: 7.716560, name: 'Bisse Ergish', videoUrl: 'https://www.youtube.com/embed/-jxalTG-FwA?si=RsEXxyB3KbJGE530' },
        { lat: 46.315737, lng: 7.878746, name: 'bisse de Wiitgartneri', videoUrl: 'https://www.youtube.com/embed/bBAPooTGRes?si=_y3IF4EBYZ1wduQS' },
        { lat: 46.283395, lng: 7.705079, name: 'Bisse de Fätschi', videoUrl: 'https://www.youtube.com/embed/eo5nm8yZIKQ?si=DNlA45cKl7NQBp1-' },
        { lat: 46.285460, lng: 7.483172, name: 'Grand bisse de Lens', videoUrl: 'https://www.youtube.com/embed/Tc17UxwCZ5U?si=TgegWFu-xcnJpQ-K' },
        { lat: 46.279128, lng: 7.375010, name: 'Bisse de Grimisuat', videoUrl: 'https://www.youtube.com/embed/SpY11SJjMQg?si=7W5LoVGnuvgedpON' },
        { lat: 46.321741, lng: 7.531962, name: 'Bisse Neuf', videoUrl: 'https://www.youtube.com/embed/W4SzL0_WOCs?si=MEl4SPMsHuRTLq4P' },
        { lat: 46.326477, lng: 7.455926, name: 'Bisse du Ro', videoUrl: 'https://www.youtube.com/embed/ILne5nQI-BA?si=CMNH72DjZQPWRlG1' },
        { lat: 46.242591, lng: 7.355827, name: 'Bisse de Lentine', videoUrl: 'https://www.youtube.com/embed/i_7UO3NArHk?si=3itcjTm7m8OBnvNB' },
        { lat: 46.291469, lng: 7.320682, name: 'Petit-Bisse', videoUrl: 'https://www.youtube.com/embed/mBptJBqaQw8?si=3hZwux5GKuEgMNYn' },
        { lat: 46.228909, lng: 7.337270, name: 'Bisse de Mont orge', videoUrl: 'https://www.youtube.com/embed/4LZQ9OczN-A?si=yD5yKFuCTnPZi_RK' },
        
        // Ajoutez d'autres bisses ici
    ];

    const text1 = "Depuis 2022, je me suis lancé dans un projet ambitieux : parcourir les bisses du Valais tout en jouant du small pipe ou Uilleann pipe. Mon objectif est de créer une série de vidéos, chacune dédiée à un bisse différent, où je joue un ou deux airs. Ce projet me permet de découvrir la richesse de la région, tout en alliant randonnée et musique."
    const text2 = "Ce projet n’est pas seulement un voyage musical. Il vise aussi à faire prendre conscience que l'eau est une ressource particulièrement précieuse dans une région comme le Valais.. Les bisses, avec leur histoire riche, sont un symbole de cette relation entre l’homme et la nature. Avec plus de 200 bisses à parcourir, c’est un projet au long cours, un défi personnel et artistique que je partage sur ma chaîne YouTube ou sur Instagram. Chaque vidéo est une nouvelle étape dans ce voyage à travers le Valais."
    return (

        <>
            <ProjectPresentation title="Voyage musical à travers les bisses du Valais"
                paragraph1={text1}
                paragraph2={text2}
                font_img={font_bisse}
                alt=" bisse valaisan" />
            <Map bisseLocations={bisseLocations} />
           
        </>
    );
};
