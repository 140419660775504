import { MainTitle } from "./Titles";
import '../../styles/project.css'

export const ProjectPresentation = ({ title , paragraph1, paragraph2, font_img, alt_font_img}) => {

    return(
        <div className="project_content">
            <MainTitle title={title}/>
            <p> {paragraph1} </p>
            <p> {paragraph2} </p>
            </div>              
    )

}