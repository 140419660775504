import '../../styles/QrCode.css';
import vins from '../../assets/Vins_2.png'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWineGlassEmpty } from '@fortawesome/free-solid-svg-icons';

function ManageQrCode(){
    return (
        <div>
            <div className='top_link_qrCode_contain'>
             <Button as={Link} to="/cave/vins" className='top_link_qrCode'> 
                <FontAwesomeIcon icon={faWineGlassEmpty} style={{ marginRight: '8px' }} />
                Consulter les vins </Button>
                </div>
            <div className="image-container">
               
            <img src={vins} alt="QrCode" className="responsive-image"/>       
            </div>
        
        <div className='bottom_link_qrCode_contain'>
           <Button as={Link} to="/cave/vins" className='bottom_wines_qrCode'> 
           <FontAwesomeIcon icon={faWineGlassEmpty} style={{ marginRight: '8px' }} />
           Consulter les vins </Button>
           </div>
        
           <div>
           </div>
        </div>
        
    );
}

export default ManageQrCode;