import {Welcome,  Presentation } from "../components/home/HomeDisplay";
import { motion } from 'framer-motion';
/**
 * Screen for the Home page. 
 */

export default function Home() {



    return (
        <>
           
        <Welcome/>
        <Presentation/>
 
        </>
    )
}