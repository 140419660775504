import { Title } from "../ui/Utilities";
import vignoble_img from '../../assets/vignoble.png'
import '../../styles/Vineyard.css'
import { AnimatedDiv } from "../ui/Animations";

export const Vignoble = () => {

   const title1 = "Erwan Tapparel :"
   const title1_part2= "La jeune vigne qui grandit"
   const title2 = "L’art du vin, sans artifice"
   const title3 = "Un Avenir Prometteur"
   const title4 = "Une vision responsable"
   const paragraph1 = "Je m'appelle Erwan Tapparel, et j'ai toujours eu les pieds dans les vignes. Après avoir terminé l'école d'œnologie de Changins en 2023, j'ai décidé de donner vie à mon propre domaine viticole, autour de Corin en Valais. Nous travaillons aujourd'hui sur environ 1 hectare de vignes, avec un respect profond pour la nature et un engagement pour une viticulture raisonnée. 2500 m²  me sont réservés pour la vinification, le reste étant livré ou ne produisant pas encore. Mon objectif ? Faire grandir le domaine tout en préservant l'authenticité de chaque cépage."
   const paragraph2 = "Au cœur de nos 2500 m² de vignoble, je m’efforce d’intervenir le moins possible sur le vin, laissant le terroir s’exprimer pleinement. Les cépages que je cultive, du Fendant au Païen pour les blancs, et du Diolinoir à la Syrah pour les rouges, sont travaillés avec une approche raisonnée. Cela signifie que chaque décision est prise avec soin, en respectant autant que possible la nature et l’humain."
   const paragraph3_text1 = "Mon objectif est clair : faire grandir le domaine pas à pas, avec une attention particulière portée à trois cépages blancs et trois cépages rouges qui me tiennent à cœur. Pour les blancs, je souhaiterais me concentrer sur la Petite Arvine, la Rèze, et le Païen, des cépages qui incarnent la richesse et la diversité de notre terroir. Côté rouge, l’idée est de travailler avec le Cornalin, la Syrah, et le Gamay, des variétés qui offrent des vins de caractère, intenses et élégants."
   const paragraph3_text2 = "La volonté de vinifier ces cépages s’inscrit dans une démarche d’anticiper le changement climatique déjà bien présent, tout en mettant en valeur les cépages autochtones ou ayant un lien avec le Rhône."
   const paragraph4 = "Mon engagement pour une viticulture raisonnée se traduit par des pratiques respectueuses de l’environnement, avec des sols enherbés et une intervention minimale sur le vin. Chaque bouteille que je produis est le fruit de ce travail patient et passionné, un hommage à la nature et au terroir. Venez découvrir mes vins, jeunes mais pleins de promesses, qui reflètent une vision moderne du vin.";
  
  return(
  <div className="vignoble_content">
      <Title title="Le vignoble"/>
      <AnimatedDiv delay={0.5}>
      <div className="vignoble_part1_content">
         <h4 className="vignoble_title">{title1} <br/> {title1_part2}  </h4>
         <p className="vignoble_paragraph"> {paragraph1} </p>
      </div>

      </AnimatedDiv>
      <AnimatedDiv delay={0.5}>
      <img src={vignoble_img} alt="vignoble et erwan tapparel" className="vignoble_img"/>
      </AnimatedDiv>
      <AnimatedDiv delay={0.5}>
      <div className="vignoble_part2_content">
    
      <h4 className="vignoble_title"> {title2} </h4>
      <p  className="vignoble_paragraph"> {paragraph2} </p>
      <h4 className="vignoble_title"> {title3} </h4>
      <p  className="vignoble_paragraph"> {paragraph3_text1} <br/> {paragraph3_text2}</p>
      <h4 className="vignoble_title"> {title4} </h4>
      <p  className="vignoble_paragraph"> {paragraph4} </p>
   
      </div>
      </AnimatedDiv>
   </div>
  )
}