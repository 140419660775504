import '../../styles/footer.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";

export const FooterMusic = () => {
    return (
        <div className="content_music">
            <div className="top">
                <div className="column">
                    <ul>
                        <li> Rue du Pressoir 32 </li>
                        <li> 3960 Corin </li>
                    </ul>
                </div>
                <div className="column">
                    <ul>
                        <li> Erwan Tapparel </li>
                        <li> 078 679 67 54 </li>
                        <li> musique@erwantapparel.ch </li>
                    </ul>
                </div>
                <div className="column">
                    <ul>
                        <li className="social_media">
                          

                            <a href="https://www.facebook.com/erwantapparel"
                                className="facebook social">
                                <FontAwesomeIcon icon={faFacebook} size="2x" target="_blank"/>
                            </a>
                            <a href="https://www.instagram.com/erwantapparel"
                                className="insta social">
                                <FontAwesomeIcon icon={faInstagram} size="2x" target="_blank"/>
                            </a>
                            <a href="https://www.youtube.com/@tapparelpiping"
                                className="youtube social">
                                <FontAwesomeIcon icon={faYoutube} size="2x" target="_blank"/>
                            </a>
                        </li>
                        <li>&#9400; 2024 - Made with💡by WebInBlocks  </li>
                    </ul>
                   
                </div>
              
            </div>

        </div>
    )
}