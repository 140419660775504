import { collection, query, where, getDocs, updateDoc, addDoc } from "firebase/firestore";
import { db } from "../../../config/InitFirebase";

export const AddOrUpdateClient = async (orderId) => {
    const clientCollectionRef = collection(db, "clients");
    const facturation_data = JSON.parse(localStorage.getItem('facturation_data'));

    console.log('facturation_data:', facturation_data); // Ajoutez ceci pour vérifier les données

        try {
            if (facturation_data && facturation_data.email) {
             // Vérifier si un client existe déjà avec la même adresse e-mail
             const q = query(clientCollectionRef, where('email', '==', facturation_data.email));
             const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
             // Si un client existe déjà, ajouter le nouvel orderId au tableau des orderId
             const updatePromises = querySnapshot.docs.map(async (doc) => {
                const clientData = doc.data();
                const updatedOrderIds = clientData.orderIds ? [...clientData.orderIds, orderId] : [orderId];
                await updateDoc(doc.ref, { orderIds: updatedOrderIds });
            });
            await Promise.all(updatePromises);
            console.log('Client(s) updated successfully with new orderId:', orderId);

            return querySnapshot.docs[0].id;
        }else{

            const newClientRef = await addDoc(clientCollectionRef, {
                clientId: orderId,
                firstname: facturation_data.firstname,
                lastname: facturation_data.lastname,
                phone_number: facturation_data.telefonNumber || null,
                email: facturation_data.email || null,
                address: facturation_data.address || null,
                NPA: facturation_data.NPA || null,
                city: facturation_data.city || null,
                orderIds: [orderId], // Utiliser un tableau pour stocker les orderId
                
            });
            console.log('Client added successfully');
            return newClientRef.id; // Retourne l'id du nouveau client ajouté
        }
    }else {
        console.error('Les données de facturation sont incomplètes ou manquantes.');
    }
        } catch (err) {
            console.error('Error adding client:', err);
            throw err; // Rejette l'erreur pour la gérer à un niveau supérieur si nécessaire
        }
};

export const AddOrder = async () => {
    const facturation_data = JSON.parse(localStorage.getItem('facturation_data'));

    const orderCollectionRef = collection(db, "orders");
    const cart = JSON.parse(localStorage.getItem('cart'));

    try {
        if (facturation_data && facturation_data.email) {
        // Créer un nouvel objet de commande avec les détails du client et les produits commandés
        const newOrder = {
            client: {
                firstname: facturation_data.firstname,
                lastname: facturation_data.lastname,
                phone_number: facturation_data.telefonNumber,
                email: facturation_data.email,
                address: facturation_data.address || "",
                NPA: facturation_data.NPA || "",
                city: facturation_data.city || "",
                delivery: facturation_data.delivery
            },
            products: cart.map((cart) => ({
                variety: cart.variety,
                millesime: cart.millesime,
                quantity: cart.quantity,
                price: cart.price,
               
            })),
            order_date: new Date().toLocaleDateString("en-US"), // Date de la commande
            // Autres champs de commande nécessaires comme le total, l'état de la commande, etc.
        };

        // Ajouter le document de commande à la collection "orders" dans Firestore
        const orderDocRef = await addDoc(orderCollectionRef, newOrder);
        
        console.log('Commande ajoutée avec succès:', newOrder);
        return orderDocRef.id;
    } else {
        console.error('Les données de facturation sont incomplètes ou manquantes.');
    }
    } catch (error) {
        console.error('Erreur lors de l\'ajout de la commande:', error);
    }
};