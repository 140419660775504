import { BasketIsNotNull, BasketIsNull  } from '../components/order_process/Basket';
import { useCart } from '../context/CartContext';
import { NextStep } from '../components/ui/Utilities';

import React from 'react';
/**
 * Screen for the Shop page. 
 */

export default function Shop() {
    const { cart, setCart } = useCart();
  
    if(cart.length > 0 || cart == []){
      return (
        <>
      <BasketIsNotNull showDelete={true} showCondition={true}/>
      <NextStep router='/cave/panier/details-facturation' title="Prochaine étape" />
      </>
      )
    }
    return <BasketIsNull/>
  };
  