import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { useLocation } from "react-router-dom";
import { useEffect } from 'react';
import '../../styles/animations.css'
import train from '../../assets/train.jpg'
import instrument1 from '../../assets/music2.jpg'
import instrument2 from '../../assets/Cornemuse.jpg'


export const AnimatedDiv = ({ children, delay }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,   // L'animation ne se déclenche qu'une fois
        threshold: 0.1,      // Le pourcentage de visibilité nécessaire pour déclencher l'animation
    });

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
            transition={{ duration: 0.5, delay }}
        >
            {children}
        </motion.div>
    );
};
export const ImageSlider = () => {
  return (
    <div className="slider-container">
      <div className="slider">
        <div className="slide-track">
          <div className="slide_image">
            <img src={train} alt="Erwan Tapparel qui joue de la cornemuse" />
          </div>
          <div className="slide_image">
            <img src={instrument1} alt="Cornemuse en Valais dans les vignes" />
          </div>
          <div className="slide_image">
            <img src={instrument2} alt="Cornemuse en Valais" />
          </div>
          {/* Duplique les images pour créer un effet de défilement continu */}
          <div className="slide_image">
            <img src={train}  alt="Erwan Tapparel qui joue de la cornemuse" />
          </div>
          <div className="slide_image">
            <img src={instrument1} alt="Cornemuse en Valais dans les vignes"/>
          </div>
          <div className="slide_image">
            <img src={instrument2}  alt="Cornemuse en Valais" />
          </div>
        </div>
      </div>
    </div>
  );
};
